export default {
  headline: `
    ¡Muy bien hecho!
  `,
  body: `
    Para ver un resumen de sus datos en My Move, pulse el botón
    <b>Resumen</b>.
  `,
  instruction: `
    Su resumen se abrirá en una nueva pestaña del navegador.
  `,
  summaryButton: `
    Resumen
  `,
};
