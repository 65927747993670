import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import throttle from 'lodash.throttle';

const styles = EStyleSheet.create({
  base: {
    borderRadius: '$borderRadius',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    shadowColor: 'black',
    shadowOffset: {
      height: 4,
      width: -4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 0,
    margin: '1rem',
    backgroundColor: '$orange',
  },
  baseText: {
    fontFamily: '$fontFamily',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    paddingBottom: 0,
  },
  nav: {
    backgroundColor: '$rust',
    padding: '0.75rem',
    width: '7rem',
  },
  report: {
    backgroundColor: '$buff',
  },
  reportText: {
    color: '$rust',
  },
  home: {
    backgroundColor: '$ink',
  },
  footer: {
    backgroundColor: '$teal',
    padding: '0.75rem',
    margin: 0,
    borderRadius: 0,
    marginBottom: '-2.5rem',
    shadowOpacity: 0,
    borderLeftColor: '$ink',
    borderLeftWidth: 2,
    width: '10.75rem',
  },
  footerText: {
    fontWeight: 'normal',
  },
  footerLeft: {
    borderTopLeftRadius: '$borderRadius',
    borderLeftWidth: 0,
  },
  footerRight: {
    borderTopRightRadius: '$borderRadius',
  },
  homeSidebar: {
    minHeight: '5rem',
    minWidth: '15rem',
    backgroundColor: '$mint',
  },
  homeSidebarText: {
    color: '$ink',
  },
  inlineLg: {
    minHeight: '4rem',
    paddingHorizontal: '2rem',
  },
  inlineLgText: {
    fontSize: '1.75rem',
  },
  inlineMd: {
    minHeight: '3rem',
    paddingHorizontal: '2rem',
  },
  inlineMdText: {
    fontSize: '1.5rem',
  },
  '@media (min-width: 768)': {
    inlineMd: {
      minWidth: '12rem',
    },
    inlineLg: {
      minWidth: '18rem',
    },
  },
  web: {
    margin: 0,
    width: '80%',
  },
  carousel: {
    position: 'absolute',
    top: 200,
    backgroundColor: 'transparent',
    shadowOpacity: 0,
  },
  carouselPrev: {
    left: -10,
  },
  carouselNext: {
    right: -10,
  },
  carouselText: {
    fontSize: '6rem',
    color: '$ink',
  },
  carouselIsTablet: {
    top: 0,
    height: '100%',
  },
  carouselIsTabletText: {
    color: 'white',
  },
  drawerButton: {
    backgroundColor: '$teal',
    marginTop: 40,
  },
});

const getVariants = variant => {
  if (Array.isArray(variant)) {
    return variant;
  }
  return [variant];
};

class Button extends React.Component {
  handlePress = throttle(
    () => {
      const { onPress } = this.props;
      onPress();
    },
    1500,
    { leading: true, trailing: false },
  );

  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }

  render() {
    const {
      activeOpacity,
      onMouseDown,
      style,
      textStyle,
      title,
      variant,
      testID,
    } = this.props;

    return (
      <TouchableOpacity
        onMouseDown={onMouseDown}
        onPress={this.handlePress}
        activeOpacity={activeOpacity}
        style={[
          styles.base,
          ...getVariants(variant).map(v => styles[v]),
          style,
        ]}
        testID={testID}
      >
        <Text
          style={[
            styles.baseText,
            ...getVariants(variant).map(v => styles[`${v}Text`]),
            textStyle,
          ]}
          allowFontScaling={false}
        >
          {title}
        </Text>
      </TouchableOpacity>
    );
  }
}

Button.propTypes = {
  activeOpacity: PropTypes.number,
  onMouseDown: PropTypes.func,
  onPress: PropTypes.func,
  style: PropTypes.object,
  testID: PropTypes.string,
  textStyle: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

Button.defaultProps = {
  activeOpacity: 0.9,
  onMouseDown: Function.prototype,
  onPress: Function.prototype,
  style: null,
  testID: null,
  textStyle: null,
  title: '',
  variant: '',
};

export default Button;
