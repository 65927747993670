import qs from 'qs';

export default () => {
  const { code, ...rest } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  window.history.replaceState(
    {},
    window.location.title,
    window.location.href.replace(
      window.location.search,
      qs.stringify(rest, { addQueryPrefix: true }),
    ),
  );
  return code || '';
};
