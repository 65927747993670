import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { sessionActionCreators } from '~actions';
import { FullScreen, RadioButtonGroup, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as localize from './localize';

const nextScreen = ({ session: { isAppointmentScheduled } }) => (
  isAppointmentScheduled ? 'ActionPlanReferral' : null
);
const sidePanelImage = 'DiplomaGirl';

const ActionPlanIntro = ({ response, sessionActions, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, justifyContent: 'center', maxWidth: 900, alignSelf: 'center' }}>
      <Text variant="lead">
        {translate('ActionPlanIntro.body')}
      </Text>
      <Text>
        {translate('ActionPlanIntro.question')}
      </Text>
      <RadioButtonGroup
        selectedValues={[response]}
        onSelect={value => {
          sessionActions.setNextScreen('ActionPlanReferral');
          sessionActions.setIsAppointmentScheduled(value);
        }}
        options={[
          {
            value: 'Y',
            label: translate('ActionPlanIntro.yes'),
          },
          {
            value: 'N',
            label: translate('ActionPlanIntro.no'),
          },
        ]}
      />
    </View>
  </FullScreen>
);

ActionPlanIntro.propTypes = {
  response: PropTypes.string.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  response: state.session.isAppointmentScheduled,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(ActionPlanIntro),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
