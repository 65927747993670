import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  text: {
    fontSize: '$introBodyFontSize',
    backgroundColor: 'transparent',
  },
});

const IntroBody = ({ children }) => (
  <Text style={styles.text}>
    {children}
  </Text>
);

IntroBody.propTypes = {
  children: PropTypes.node,
};

IntroBody.defaultProps = {
  children: null,
};

export default IntroBody;
