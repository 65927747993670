import cookie from 'cookie';
import { configService } from '~services';

const baseUrl = configService.getConfigValue('API_URL');

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
};

const setAuthorization = () => {
  headers['X-XSRF-TOKEN'] = cookie.parse(document.cookie)['XSRF-TOKEN'];
};

const apiFetch = (resource, init) => {
  const request = new Request(
    `${baseUrl}/${resource}`,
    {
      ...init,
      body: (init && init.body) ? JSON.stringify(init.body || {}) : null,
      credentials: 'include',
      headers,
    },
  );
  return fetch(request);
};

export default {
  apiFetch,
  setAuthorization,
};
