import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import EStyleSheet from 'react-native-extended-stylesheet';

import Logo from '~logos/logo.png';
import LogoEs from '~logos/logo-es.png';
import Curve, { CURVE_HEIGHT } from '~components/Curve';

import * as images from './img';

const styles = EStyleSheet.create({
  base: {
    backgroundColor: '$buff',
    width: '50%',
    maxWidth: 720,
    zIndex: 5,
  },
  baseImage: {
    resizeMode: 'contain',
    height: '50%',
    width: '100%',
    position: 'absolute',
    bottom: 53,
  },
  logoImage: {
    resizeMode: 'contain',
    height: '25%',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    position: 'absolute',
    top: 60,
  },
  curveContainer: {
    position: 'absolute',
    bottom: CURVE_HEIGHT,
    zIndex: -1,
    width: '250%',
    marginLeft: '-150%',
    marginBottom: -40,
  },
});

const SidePanel = ({ image, language }) => (
  <View style={styles.base}>
    <Image
      style={styles.logoImage}
      source={language === 'es' ? LogoEs : Logo}
    />
    <View style={styles.curveContainer}>
      <Curve fillColor="orange" />
    </View>
    <Image
      style={styles.baseImage}
      source={images[image]}
    />
  </View>
);

SidePanel.propTypes = {
  language: PropTypes.string.isRequired,
  image: PropTypes.string,
};

SidePanel.defaultProps = {
  image: 'GirlOnBike',
};

const mapStateToProps = state => ({
  language: state.session.language,
});

export default connect(mapStateToProps)(SidePanel);
