/* eslint-env browser */

import { createMemoryHistory } from 'history';
import React from 'react';
import PropTypes from 'prop-types';
import {
  NavigationActions,
  getNavigation,
  NavigationProvider,
} from '@react-navigation/core';
import { COMPLETE_TRANSITION } from '@react-navigation/core/src/routers/StackActions';

import { navigationService } from '~services';

const history = createMemoryHistory();

const getPathAndParamsFromLocation = location => {
  const path = encodeURI(location.pathname.substr(1));
  return { path };
};

const matchPathAndParams = (a, b) => {
  if (a.path !== b.path) {
    return false;
  }
  return true;
};

let currentPathAndParams = getPathAndParamsFromLocation(history.location);

export default function createBrowserApp(App) {
  const initAction = App.router.getActionForPathAndParams(
    currentPathAndParams.path,
    currentPathAndParams.params,
  ) || NavigationActions.init();

  const setHistoryListener = dispatch => {
    history.listen(location => {
      const pathAndParams = getPathAndParamsFromLocation(location);
      if (matchPathAndParams(pathAndParams, currentPathAndParams)) {
        return;
      }
      currentPathAndParams = pathAndParams;
      const action = App.router.getActionForPathAndParams(
        pathAndParams.path,
        pathAndParams.params,
      );
      if (action) {
        dispatch(action);
      } else {
        dispatch(initAction);
      }
    });
  };

  class WebApp extends React.Component {
    _actionEventSubscribers = new Set();

    _title = document.title;

    constructor(props) {
      super(props);
      this.state = { nav: App.router.getStateForAction(initAction) };
    }

    componentDidMount() {
      setHistoryListener(this._dispatch);
      // this.updateTitle();
      const { nav } = this.state;
      this._actionEventSubscribers.forEach(subscriber => subscriber({
        type: 'action',
        action: initAction,
        state: nav,
        lastState: null,
      }));
    }

    componentDidUpdate() {
      // this.updateTitle();
    }

    _dispatch = action => {
      const { nav: lastState } = this.state;
      const newState = App.router.getStateForAction(action, lastState);
      const dispatchEvents = () => this._actionEventSubscribers.forEach(subscriber => subscriber({
        type: 'action',
        action,
        state: newState,
        lastState,
      }));
      if (newState && newState !== lastState) {
        this.setState({ nav: newState }, dispatchEvents);
        const pathAndParams = App.router.getPathAndParamsForState
          && App.router.getPathAndParamsForState(newState);
        if (
          pathAndParams
          && !matchPathAndParams(pathAndParams, currentPathAndParams)
        ) {
          currentPathAndParams = pathAndParams;
          history.push(`/${pathAndParams.path}`);
        }
        App.router.getStateForAction(
          {
            toChildKey: pathAndParams.path,
            type: COMPLETE_TRANSITION,
          },
          newState,
        );
      } else {
        dispatchEvents();
      }
    };

    updateTitle() {
      const { state } = this._navigation;
      const childKey = state.routes[state.index].key;
      const activeNav = this._navigation.getChildNavigation(childKey);
      const opts = App.router.getScreenOptions(activeNav);
      this._title = opts.title || opts.headerTitle;
      document.title = this._title;
    }

    render() {
      const { screenProps } = this.props;
      const { nav } = this.state;
      this._navigation = getNavigation(
        App.router,
        nav,
        this._dispatch,
        this._actionEventSubscribers,
        () => screenProps,
        () => this._navigation,
      );
      navigationService.setTopLevelNavigator(this._navigation);
      return (
        <NavigationProvider value={this._navigation}>
          <App navigation={this._navigation} />
        </NavigationProvider>
      );
    }
  }

  WebApp.propTypes = {
    screenProps: PropTypes.any,
  };

  WebApp.defaultProps = {
    screenProps: undefined,
  };

  return WebApp;
}
