export default {
  body: `
    Please select three coronavirus vaccine topics from this list. Select topics
    of most interest to you.
  `,
  topics: {
    A: `
      Protect Your Health
    `,
    B: `
      Prevent Infection, Prevent Serious Illness
    `,
    C: `
      Vaccine Safety
    `,
    D: `
      Future Regrets
    `,
    E: `
      Protect the Health of Others
    `,
    F: `
      Protect Your Community’s Health
    `,
    G: `
      Help People Get Back to Work & School
    `,
    H: `
      Feel More Secure
    `,
  },
  instruction: `
    After you have selected three, click the <b>Continue</b> button.
  `,
};
