import {
  START_RECORDING,
  START_RECORDING_PLAYBACK,
  STOP_RECORDING,
  STOP_RECORDING_PLAYBACK,
} from './types';

export const startRecording = screenName => ({
  type: START_RECORDING,
  screenName,
});

export const stopRecording = screenName => ({
  type: STOP_RECORDING,
  screenName,
});

export const startRecordingPlayback = screenName => ({
  type: START_RECORDING_PLAYBACK,
  screenName,
});

export const stopRecordingPlayback = () => ({
  type: STOP_RECORDING_PLAYBACK,
});
