import { alertApi } from '~api';

export default ({ authActions, mediaActions, translate }) => {
  mediaActions.releaseSound();
  mediaActions.unloadScreenNarration();

  alertApi.confirm(
    translate('Home.personalLogoutAlert.title'),
    translate('Home.personalLogoutAlert.message'),
    [
      {
        text: translate('Home.personalLogoutAlert.negative'),
        style: 'cancel',
      },
      {
        text: translate('Home.personalLogoutAlert.positive'),
        onPress: () => authActions.resetApp(),
        style: 'destructive',
      },
    ],
  );
};
