import React from 'react';
import {
  ActivityIndicator,
  View,
} from 'react-native';

const Loading = () => (
  <View style={{
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <ActivityIndicator />
  </View>
);

export default Loading;
