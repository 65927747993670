export default {
  headline: `
    Great job!
  `,
  body: `
    To open a Summary of your My Move information, click the <b>Summary</b>
    button.
  `,
  instruction: `
    Your Summary will open in a new web browser tab.
  `,
  summaryButton: `
    Summary
  `,
};
