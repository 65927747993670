export default {
  body: `
    Puede que el vacunarse le parezca algo simple, pero es importante que lo
    planifique para evitar descuidarse de ello.
  `,
  question: `
    ¿Ya tiene agendada la cita médica en la que se le aplicará la vacuna contra 
    el coronavirus?
  `,
  yes: `
    Sí
  `,
  no: `
    No
  `,
};
