import {
  FETCH_AUTH,
  RESET_APP,
} from './types';

export const fetchAuth = code => ({
  type: FETCH_AUTH,
  code,
});

export const resetApp = () => ({
  type: RESET_APP,
});
