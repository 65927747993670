import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';

import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

const ImagePanel = ({ source }) => (
  <View style={styles.container}>
    <Image
      resizeMode="cover"
      style={styles.image}
      source={source}
    />
  </View>
);

ImagePanel.propTypes = {
  source: PropTypes.number.isRequired,
};

export default ImagePanel;
