import React from 'react';
import PropTypes from 'prop-types';
import { Animated, Image, View } from 'react-native';
import { connect } from 'react-redux';
import EStyleSheet from 'react-native-extended-stylesheet';

import Curve, { CURVE_HEIGHT } from '~components/Curve';

import images from './img';

export const SIDEPANEL_WIDTH = 200;

const translateY = new Animated.Value(0);

const styles = EStyleSheet.create({
  base: {
    backgroundColor: '$buff',
    width: SIDEPANEL_WIDTH,
    zIndex: 5,
    overflow: 'hidden',
  },
  baseImage: {
    resizeMode: 'contain',
    width: SIDEPANEL_WIDTH - 20,
    left: 10,
    position: 'absolute',
    bottom: 45,
    height: 200,
  },
  curveContainer: {
    position: 'absolute',
    bottom: CURVE_HEIGHT,
    zIndex: -1,
    width: SIDEPANEL_WIDTH + 8,
    marginLeft: -8,
    marginBottom: -30,
  },
  imageContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    transform: [
      { translateY },
      { perspective: 1000 },
    ],
  },
});

class RotatingSidePanel extends React.Component {
  componentDidUpdate({ height: prevHeight }) {
    const { activeImageIndex, height } = this.props;
    // TODO: check offset. why 100? should be derived from footer height and curve height?
    Animated.timing(
      translateY,
      {
        delay: height === prevHeight ? 500 : 0,
        duration: height === prevHeight ? 500 : 0,
        toValue: activeImageIndex * -(height - 100),
      },
    ).start();
  }

  render() {
    const { activeImages } = this.props;

    return (
      <View style={styles.base}>
        <View style={{ flex: 1 }}>
          <Animated.View
            style={[
              styles.imageContainer,
            ]}
          >
            {activeImages.map((label, i) => (
              <View
                key={i}
                style={{ height: '100%' }}
              >
                <Image
                  style={[styles.baseImage, label && images[label].style]}
                  source={label && images[label].image}
                />
              </View>
            ))}
          </Animated.View>
        </View>
        <View style={styles.curveContainer}>
          <Curve fillColor="orange" />
        </View>
      </View>
    );
  }
}

RotatingSidePanel.propTypes = {
  activeImages: PropTypes.array.isRequired,
  activeImageIndex: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  activeImages: state.session.activeImages,
  activeImageIndex: state.session.activeImageIndex,
  height: state.device.height,
});

export default connect(mapStateToProps)(RotatingSidePanel);
