export default {
  tryAgainBody: `
    Cuando le remitieron a My Move, deberían haberle dado una clave de acceso.
    Vuelva a leer el mensaje original para encontrar la clave.
  `,
  tryAgainBody2: `
    Si ha accedido a My Move haciendo clic en un enlace que aparece en un
    mensaje de correo electrónico o en otro sitio web, hágalo de nuevo.
  `,
  watchVideoBody: `
    Aunque no tenga una clave de acceso, puede ver el vídeo de My Move.
  `,
  tryAgainButton: `
    Ingresar su clave de acceso
  `,
  watchVideoButton: `
    Ver el video
  `,
};
