import React from 'react';
import PropTypes from 'prop-types';
import { Text as RNText, TextPropTypes } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  base: {
    color: 'white',
    fontFamily: '$fontFamily',
    fontSize: '$bodyFontSize',
    paddingBottom: '2rem',
  },
  lead: {
    fontSize: '$leadFontSize',
    fontWeight: 'bold',
    paddingBottom: '2rem',
  },
  small: {
    fontSize: '$smallFontSize',
    fontWeight: 'normal',
  },
  label: {
    paddingBottom: '0.25rem',
    paddingTop: '1.75rem',
  },
  headline: {
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  poster: {
    textAlign: 'center',
    fontSize: '$posterFontSize',
    fontWeight: 'bold',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    color: '$putty',
  },
  instruction: {
    textAlign: 'center',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    color: '$putty',
  },
  error: {
    color: '$rust',
    marginBottom: 40,
    marginTop: 20,
    padding: 20,
    borderWidth: 0,
    backgroundColor: '$putty',
  },
  summaryTitle: {
    fontSize: '$leadFontSize + 6',
    fontWeight: 'bold',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    color: '$ink',
    textAlign: 'center',
  },
  summarySubtitle: {
    fontWeight: 'bold',
    paddingTop: '2rem',
    paddingBottom: '1rem',
    color: '$ink',
  },
  summaryIntro: {
    fontWeight: '300',
    color: 'black',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    marginTop: '0.5rem',
    marginBottom: '2rem',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '$ink',
    borderTopWidth: 1,
    borderTopColor: '$ink',
  },
  summaryItem: {
    fontWeight: '300',
    color: 'black',
    paddingBottom: '0.7rem',
    paddingLeft: '1.5rem',
  },
  summary: {
    fontWeight: '300',
    color: 'black',
    paddingBottom: '0.7rem',
  },
});

const Text = ({
  children,
  style,
  variant,
}) => (
  <RNText
    style={[
      styles.base,
      styles[variant],
      style,
    ]}
    allowFontScaling={false}
  >
    {children}
  </RNText>
);

Text.propTypes = {
  children: PropTypes.node,
  style: TextPropTypes ? TextPropTypes.style : PropTypes.object,
  variant: PropTypes.string,
};

Text.defaultProps = {
  children: null,
  style: null,
  variant: 'default',
};

export default Text;
