import { authService } from '~services';

export const CODE_ERROR = 'code';
export const NETWORK_ERROR = 'network';

class AuthApi {
  static async getToken() {
    const response = await authService.apiFetch(
      'sanctum/csrf-cookie',
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      return response;
    }

    throw new Error(NETWORK_ERROR);
  }

  static async getProperty(code) {
    const response = await authService.apiFetch(`start-session/${code}`);

    const json = await response.json();

    if (response.ok) {
      authService.setAuthorization();
      return {
        ...json.data,
        createdAt: Date.now(),
      };
    }

    switch (response.status) {
      case 422:
        throw new Error(CODE_ERROR);
      case 403:
      case 429: {
        const e = Error(json.message);
        e.useMessage = true;
        throw e;
      }
      default:
        throw new Error(NETWORK_ERROR);
    }
  }
}

export default AuthApi;

// 2019-08-26 11:46:03
