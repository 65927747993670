export default {
  body: `
    How can getting the vaccine protect people in your household and other
    family and friends?
  `,
  topic: `
    How getting the vaccine can protect people in my household and other
    family and friends.
  `,
};
