export default {
  body: `
    If you decide NOT to get the coronavirus vaccine, why would you regret or
    feel sorry about that decision in the future?
  `,
  topic: `
    Why I would regret or feel sorry in the future if I decide NOT to get
    the coronavirus vaccine.
  `,
};
