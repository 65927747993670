require('dotenv').config();

const getMetaValue = key => {
  const node = document.querySelector(`meta[property=${key}]`);
  if (node !== null) {
    return node.content;
  }
  return null;
};

const getConfigValue = key => {
  if (process.env && process.env[key] !== undefined) {
    return process.env[key];
  }
  return getMetaValue(key);
};

export default { getConfigValue, getMetaValue };
