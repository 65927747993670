export default {
  body: `
    Si decide NO vacunarse contra el coronavirus, ¿por qué tendría que
    arrepentirse o lamentar esa decisión en el futuro?
  `,
  topic: `
    Por qué tendría que arrepentirme o lamentar en el futuro el hecho de haber
    decidido NO vacunarme contra el coronavirus.
  `,
};
