import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { mediaActionCreators, sessionActionCreators } from '~actions';
import { sessionApi } from '~api';
import Button from '~components/Button';
import { getTranslateParameters } from '~selectors';

const ReportButton = ({
  report,
  title,
  translate,
  variant,
}) => (
  <Button
    onMouseDown={async () => {
      const summaryWindow = window.open('', '_blank');
      try {
        const url = await sessionApi.getReportUrl({ report });
        if (url) {
          summaryWindow.location.href = url;
        } else {
          throw new Error();
        }
      } catch (e) {
        summaryWindow.close();
      }
    }}
    title={title || translate('HomeButton.home')}
    variant={variant}
  />
);

ReportButton.propTypes = {
  report: PropTypes.object.isRequired,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  variant: PropTypes.array,
};

ReportButton.defaultProps = {
  title: null,
  variant: ['report'],
};

const mapStateToProps = state => {
  const { session } = state;
  const translate = key => getTranslate(state.locale)(key, getTranslateParameters(state));
  const report = {
    concerns: session.selectedConcerns,
    isAppointmentScheduled: session.isAppointmentScheduled === 'Y',
    isNotReady: (session.readinessQuestion2 === '2' || session.readinessQuestion3 === '2'),
    isUnsure: session.readinessQuestion3 === '1',
    isReady: (session.readinessQuestion2 === '3' || session.readinessQuestion3 === '3'),
    locale: session.language,
    questions: session.selectedQuestions,
  };

  return {
    report,
    translate,
  };
};

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportButton);
