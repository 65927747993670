import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  button: {
    backgroundColor: 'white',
    borderColor: 'white',
    borderWidth: '0.25rem',
    flex: 0,
    zIndex: 25,
    height: '3rem',
    borderRadius: '$borderRadius',
    flexBasis: '3rem',
    padding: '0.33rem',
  },
  buttonSelected: {
    backgroundColor: '$rust',
  },
  container: {
    minHeight: '4rem',
    borderRadius: '$borderRadius',
    marginVertical: '0.25rem',
  },
  containerSelected: {
  },
  inner: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontFamily: '$fontFamily',
    fontSize: '$bodyFontSize',
    color: 'white',
    paddingLeft: '1rem',
    paddingVertical: '1rem',
  },
  textSelected: {
    fontWeight: 'bold',
  },
});

const RadioButton = ({ children, isSelected, onPress }) => (
  <TouchableOpacity
    style={[styles.container, isSelected && styles.containerSelected]}
    onPress={onPress}
  >
    <View style={styles.inner}>
      <View
        style={[styles.button, isSelected && styles.buttonSelected]}
      >
        <svg width="100%" height="100%" viewBox="0 0 24 24">
          <path
            d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
            stroke="white"
            fill="white"
          />
        </svg>
      </View>
      <Text style={[styles.text, isSelected && styles.textSelected]}>
        {children}
        {' '}
        {isSelected}
      </Text>
    </View>
  </TouchableOpacity>
);

RadioButton.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  children: null,
};

export default RadioButton;
