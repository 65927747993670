export default {
  body: `
    My Move ofrece información sobre la vacuna contra el coronavirus.
  `,
  question: `
    ¿Qué afirmación describe mejor su opinión sobre la aplicación de la
    vacuna contra el coronavirus?
  `,
  answer1: `
    Jamás he pensado en vacunarme contra el coronavirus.
  `,
  answer2: `
    Estoy indeciso sobre la posibilidad de vacunarme contra el coronavirus.
  `,
  answer3: `
    NO quiero vacunarme contra el coronavirus.
  `,
  answer4: `
    Yo SÍ quiero vacunarme contra el coronavirus.
  `,
  instruction: `
    Pulse el botón de la oración que mejor describe su opinión.
  `,
};
