import { createLogic } from 'redux-logic';
import { setActiveLanguage } from 'react-localize-redux';

import {
  mediaActionCreators as mediaActions,
} from '~actions';
import {
  CHECK_FOR_SOLO_SESSION,
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_ERROR,
  SET_PARAM,
  SET_LANGUAGE,
  TOGGLE_SELECTION,
  ADD_SELECTION,
  REMOVE_SELECTION,
  START_RECORDING_SUCCESS,
  STORE_RECORDING_FILENAME,
  SET_TIMER,
  RETRIEVE_SESSION,
  RETRIEVE_SESSION_SUCCESS,
  RETRIEVE_SESSION_ERROR,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_ERROR,
} from '~actions/types';
import { sessionApi, storageApi } from '~api';
import { navigationService } from '~services';

const createSessionLogic = createLogic({
  type: CREATE_SESSION,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: CREATE_SESSION_SUCCESS,
    failType: CREATE_SESSION_ERROR,
  },
  async process({ getState }) {
    const { session: { language } } = getState();
    const session = await sessionApi.createSession();
    session.language = language;
    session.isFooterHomeEnabled = true;
    return { session };
  },
});

const storeSessionLogic = createLogic({
  type: [SET_PARAM, ADD_SELECTION, REMOVE_SELECTION, TOGGLE_SELECTION, STORE_RECORDING_FILENAME],
  process({ getState }, dispatch, done) {
    const { session } = getState();
    if (session.isActive) {
      storageApi.storeSession(session);
    }
    done();
  },
});

const toggleSelectionLogic = createLogic({
  type: [TOGGLE_SELECTION],
  process({ action, getState }, dispatch, done) {
    const { param, value } = action;
    const { session: { [param]: selections } } = getState();
    if (selections.includes(value)) {
      dispatch({
        type: REMOVE_SELECTION,
        param,
        value,
      });
    } else if (!['selectedQuestions', 'selectedPeerArguments'].includes(param)) {
      dispatch({
        type: ADD_SELECTION,
        param,
        value,
      });
    } else if (selections.length < 3) {
      dispatch({
        type: ADD_SELECTION,
        param,
        value,
      });
    }
    done();
  },
});

const addRestrictedSelectionLogic = createLogic({
  type: [ADD_SELECTION],
  validate({ action, getState }, allow, reject) {
    const { param } = action;
    const { session: { [param]: selections } } = getState();
    if (['selectedQuestions', 'selectedPeerArguments'].includes(param) && selections.length >= 3) {
      reject(action);
    } else {
      allow(action);
    }
  },
});

const storeRecordingFilenameLogic = createLogic({
  type: START_RECORDING_SUCCESS,
  processOptions: {
    dispatchReturn: true,
    successType: STORE_RECORDING_FILENAME,
  },
  process({ action: { payload: { filename, screenName } } }) {
    return {
      filename,
      screenName,
    };
  },
});

const retrieveSessionLogic = createLogic({
  type: RETRIEVE_SESSION,
  async process({ action: { passcode } }, dispatch, done) {
    try {
      const session = await storageApi.retrieveStoredSessionByPasscode(passcode);
      dispatch({
        type: RETRIEVE_SESSION_SUCCESS,
        session,
      });
      dispatch(setActiveLanguage(session.language || 'en'));
      dispatch({
        type: SET_PARAM,
        param: 'isFooterHomeEnabled',
        value: true,
      });
    } catch (error) {
      const failedAttempts = await storageApi.retrieveFailedAttempts();
      if (failedAttempts > 100) {
        await storageApi.deleteAllSessions();
        await storageApi.clearFailedAttempts();
        dispatch({
          type: RETRIEVE_SESSION_ERROR,
          error: new Error('too_many_failed_attempts'),
        });
      } else {
        await storageApi.incrementFailedAttempts();
        dispatch({
          type: RETRIEVE_SESSION_ERROR,
          error,
        });
      }
    }
    return done();
  },
});

const sessionSuccessLogic = createLogic({
  type: [CREATE_SESSION_SUCCESS, RETRIEVE_SESSION_SUCCESS],
  process(_, dispatch, done) {
    dispatch(mediaActions.releaseSound());
    dispatch({ type: SET_TIMER });
    navigationService.navigate('Home');
    return done();
  },
});

const deleteSessionLogic = createLogic({
  type: DELETE_SESSION,
  processOptions: {
    dispatchReturn: true,
    successType: DELETE_SESSION_SUCCESS,
    failType: DELETE_SESSION_ERROR,
  },
  async process({ getState }) {
    const { session } = getState();
    return storageApi.deleteSession(session);
  },
});

const setLanguageLogic = createLogic({
  type: [SET_LANGUAGE],
  process({ action: { value } }, dispatch, done) {
    dispatch(setActiveLanguage(value));
    dispatch(mediaActions.releaseSound());
    dispatch(mediaActions.loadSound());
    dispatch(mediaActions.playSound());
    done();
  },
});

const soloSessionLogic = createLogic({
  type: [CHECK_FOR_SOLO_SESSION],
  async process({ getState }, dispatch, done) {
    const { session: { language } } = getState();
    const session = {};
    session.language = language;
    session.isFooterHomeEnabled = true;
    dispatch({
      type: CREATE_SESSION_SUCCESS,
      payload: { session },
    });
    done();
  },
});

export default [
  createSessionLogic,
  storeSessionLogic,
  toggleSelectionLogic,
  addRestrictedSelectionLogic,
  storeRecordingFilenameLogic,
  retrieveSessionLogic,
  sessionSuccessLogic,
  deleteSessionLogic,
  setLanguageLogic,
  soloSessionLogic,
];
