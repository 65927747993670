export default {
  body: `
    ¿Cómo puede la vacuna proteger a las personas de su hogar y a otros
    familiares y amigos?
  `,
  topic: `
    Cómo puede la vacuna proteger a las personas de mi hogar y a otros
    familiares y amigos.
  `,
};
