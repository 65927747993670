import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { View } from 'react-native';

import * as audio from './audio';
import * as localize from './localize';

import { mediaActionCreators } from '~actions';
import { Button, FullScreen, SidePanel, Text } from '~components';
import { getDimensions } from '~selectors';
import { navigationService } from '~services';

const LandingNoKey = ({ isTablet, mediaActions, translate }) => (
  <View style={{ flex: 1, flexDirection: 'row' }}>
    {
      isTablet && <SidePanel />
    }
    <FullScreen>
      <View style={{ maxWidth: 800, alignSelf: 'center' }}>
        <Text>
          {translate('LandingNoKey.tryAgainBody')}
        </Text>
        <Text>
          {translate('LandingNoKey.tryAgainBody2')}
        </Text>
        <Text>
          {translate('LandingNoKey.watchVideoBody')}
        </Text>
        <Button
          variant="inlineMd"
          onPress={() => {
            mediaActions.releaseSound();
            mediaActions.unloadScreenNarration();
            navigationService.back();
          }}
          style={{ marginBottom: 20 }}
          title={translate('LandingNoKey.tryAgainButton')}
        />
        <Button
          variant="inlineMd"
          onPress={() => {
            mediaActions.releaseSound();
            mediaActions.unloadScreenNarration();
            navigationService.navigate('LandingVideo');
          }}
          title={translate('LandingNoKey.watchVideoButton')}
          style={{ backgroundColor: 'transparent', shadowOpacity: 0, borderWidth: 1, borderColor: 'white' }}
        />
      </View>
    </FullScreen>
  </View>
);

LandingNoKey.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isTablet: getDimensions(state).isTablet,
  translate: key => getTranslate(state.locale)(key),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(LandingNoKey),
  {
    audio,
    localize,
  },
);
