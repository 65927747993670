import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, ResponsiveStacker, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const nextScreen = ({ session: { readinessQuestion3 } }) => (
  readinessQuestion3 === '3' ? 'ActionPlanIntro' : 'ConcernsSelection'
);
const sidePanelImage = 'Globe';

const ReadinessQuestion3Acknowledgment = ({ response, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 900, alignSelf: 'center' }}>
      <ResponsiveStacker>
        <View
          style={{
            flexBasis: 300,
            flexGrow: 0,
            maxWidth: '100%',
            aspectRatio: 1,
          }}
        >
          <Image
            source={images.DefaultSpotImage}
            style={{
              flexBasis: 300,
              maxWidth: '100%',
            }}
            resizeMode="contain"
          />
        </View>
        <Text variant="lead">
          {translate(`ReadinessQuestion3Acknowledgment.${response}Body`)}
        </Text>
      </ResponsiveStacker>
    </View>
  </FullScreen>
);

ReadinessQuestion3Acknowledgment.propTypes = {
  response: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  response: {
    '1': 'unsure',
    '2': 'no',
    '3': 'yes',
  }[state.session.readinessQuestion3] || 'unsure',
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(ReadinessQuestion3Acknowledgment),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
