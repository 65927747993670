import { createLogic } from 'redux-logic';

import { mediaApi } from '~api';

import {
  LOAD_SOUND,
  LOAD_SOUND_SUCCESS,
  LOAD_SOUND_ERROR,
  PLAY_SOUND,
  PLAY_SOUND_SUCCESS,
  PLAY_SOUND_ERROR,
  PAUSE_SOUND,
  PAUSE_SOUND_SUCCESS,
  PAUSE_SOUND_ERROR,
  STOP_SOUND,
  STOP_SOUND_SUCCESS,
  STOP_SOUND_ERROR,
  RELEASE_SOUND,
  RELEASE_SOUND_SUCCESS,
  RELEASE_SOUND_ERROR,
  FETCH_AUTH_SUCCESS,
} from '~actions/types';

const loadSoundLogic = createLogic({
  type: LOAD_SOUND,
  processOptions: {
    dispatchReturn: true,
    successType: LOAD_SOUND_SUCCESS,
    failType: LOAD_SOUND_ERROR,
  },
  validate: (depObj, allow, reject) => mediaApi.loadSound(depObj, allow, reject),
  process({ action }) {
    return action;
  },
});

const validateSound = ({ action, getState }, allow, reject) => {
  const { media: { isAudioEnabled, sound } } = getState();
  switch (action.type) {
    case LOAD_SOUND_SUCCESS:
    case FETCH_AUTH_SUCCESS:
      return allow(action);
    default:
      return (sound && isAudioEnabled) ? allow(action) : reject();
  }
};

const playSoundLogic = createLogic({
  type: [PLAY_SOUND, LOAD_SOUND_SUCCESS],
  processOptions: {
    dispatchReturn: true,
    successType: PLAY_SOUND_SUCCESS,
    failType: PLAY_SOUND_ERROR,
  },
  validate: validateSound,
  process: mediaApi.playSound,
});

const pauseSoundLogic = createLogic({
  type: PAUSE_SOUND,
  processOptions: {
    dispatchReturn: true,
    successType: PAUSE_SOUND_SUCCESS,
    failType: PAUSE_SOUND_ERROR,
  },
  validate: validateSound,
  process: mediaApi.pauseSound,
});

const stopSoundLogic = createLogic({
  type: STOP_SOUND,
  processOptions: {
    dispatchReturn: true,
    successType: STOP_SOUND_SUCCESS,
    failType: STOP_SOUND_ERROR,
  },
  validate: validateSound,
  process: mediaApi.stopSound,
});

const releaseSoundLogic = createLogic({
  type: [RELEASE_SOUND, FETCH_AUTH_SUCCESS],
  processOptions: {
    dispatchReturn: true,
    successType: RELEASE_SOUND_SUCCESS,
    failType: RELEASE_SOUND_ERROR,
  },
  validate: validateSound,
  process: mediaApi.releaseSound,
});

export default [
  loadSoundLogic,
  playSoundLogic,
  pauseSoundLogic,
  stopSoundLogic,
  releaseSoundLogic,
];
