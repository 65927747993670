export default {
  question: `
    After watching the video, which statement best describes your thoughts
    about getting the coronavirus vaccine?
  `,
  answer1: `
    I am undecided about getting the coronavirus vaccine.
  `,
  answer2: `
    I do NOT want to get the coronavirus vaccine.
  `,
  answer3: `
    I DO want to get the coronavirus vaccine.
  `,
  instruction: `
    Click the button that best describes your thoughts.
  `,
};
