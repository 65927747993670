import { authService, configService } from '~services';

class SessionApi {
  static sendFeedback(body) {
    return authService.apiFetch(
      'feedback',
      {
        method: 'POST',
        body,
      },
    );
  }

  static async getReportUrl(body) {
    const response = await authService.apiFetch(
      'reports',
      {
        method: 'POST',
        body: {
          dictionary: parseInt(configService.getConfigValue('CI_PIPELINE_ID'), 10),
          ...body,
        },
      },
    );

    const json = await response.json();

    if (response.ok) {
      const { id, key } = json.data;
      return `${configService.getConfigValue('WEB_URL')}/summary/${id}?key=${key}`;
    }

    throw new Error(json.message);
  }

  static postEvent(event) {
    const timestamp = Date.now();
    const eventLabel = event.eventLabel ? JSON.parse(event.eventLabel) : null;
    return authService.apiFetch(
      'events',
      {
        method: 'POST',
        body: {
          ...event,
          eventLabel,
          timestamp,
        },
      },
    );
  }
}

export default SessionApi;
