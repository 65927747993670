import { recorderApi } from '~api';

import * as en from './en';
import * as es from './es';
//
const narration = { en, es };
//
export default async ({ session: { language } }) => {
  switch (await recorderApi.checkMicrophoneAccess()) {
    case recorderApi.PERMISSION_UNAVAILABLE:
      // TODO maybe? on IE11 at least we disable narration so it may not matter
      return null;
    case recorderApi.PERMISSION_AUTHORIZED:
      return narration[language]?._1;
    default:
      return narration[language]?._2;
  }
};
