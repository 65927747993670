import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    marginTop: 100,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 15,
  },
});

const ContentPanel = ({ children }) => (
  <View style={styles.container}>
    <View style={styles.inner}>
      {children}
    </View>
  </View>
);

ContentPanel.propTypes = {
  children: PropTypes.node,
};

ContentPanel.defaultProps = {
  children: null,
};

export default ContentPanel;
