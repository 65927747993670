export default {
  body: `
    Con el fin de facilitar la consulta con su proveedor de servicios de salud,
    seleccione de esta lista las inquietudes que le quedan sobre la vacuna
    contra el coronavirus.
  `,
  concerns: {
    A: `
      No creo que esté en riesgo de contraer COVID-19.
    `,
    B: `
      No creo que esté en riesgo de enfermarme si contraigo COVID-19.
    `,
    C: `
      Me preocupa la seguridad de las vacunas.
    `,
    D: `
      Me preocupan los efectos secundarios de las vacunas.
    `,
    E: `
      Creo que mi estado de salud o mi edad pueden hacer que vacunarme sea arriesgado.
    `,
    F: `
      Creo que la vacuna es demasiado nueva y quiero esperar a ver.
    `,
    G: `
      No confío en la forma en que se desarrolló o se administra la vacuna.
    `,
    H: `
      Mi proveedor no me ha recomendado la vacuna contra el coronavirus.
    `,
    I: `
      Tengo dificultades para concertar o llegar a una cita para la vacunación.
    `,
    J: `
      No se necesita la vacuna contra el coronavirus.
    `,
    K: `
      Otra razón que no se menciona aquí.
    `,
  },
};
