import * as en from './en';
import * as es from './es';

const narration = { en, es };

export default ({ session: { language, readinessQuestion1 } }) => {
  switch (readinessQuestion1) {
    case '4':
      return narration[language]?._1;
    case '1':
    case '2':
    case '3':
    default:
      return narration[language]?._2;
  }
};
