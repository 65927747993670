import { Loading } from '~screens';

import isAuthenticated from './isAuthenticated';
import isNotAuthenticated from './isNotAuthenticated';

export default {
  Loading,
  isAuthenticated,
  isNotAuthenticated,
};
