import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { View } from 'react-native';

import { authActionCreators, mediaActionCreators } from '~actions';
import { Button, FullScreen, SidePanel, Text, TextInput } from '~components';
import { getDimensions } from '~selectors';
import { navigationService } from '~services';

import * as audio from './audio';
import * as localize from './localize';
import getCode from './getCode';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { code: getCode() };
  }

  render() {
    const {
      authActions,
      error,
      isTablet,
      mediaActions,
      translate,
    } = this.props;

    const { code } = this.state;
    const submit = async () => {
      if (code.length) {
        authActions.fetchAuth(code);
      }
    };

    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {
          isTablet && <SidePanel />
        }
        <FullScreen style={{ alignItems: 'center', paddingHorizontal: 20 }}>
          {error && (
            <Text variant="error">
              {error.useMessage ? error.message : translate(`Landing.errors.${error.message}`, null, { missingTranslationMsg: error.message })}
            </Text>
          )}
          <Text variant="poster" style={{ color: 'white' }}>
            {translate('Landing.welcomeTitle')}
          </Text>
          <Text variant="lead" style={{ textAlign: 'center' }}>
            {translate('Landing.body')}
          </Text>
          <Text style={{ textAlign: 'center' }}>
            {translate('Landing.keyExplanation')}
          </Text>
          <TextInput
            placeholder={translate('Landing.codeLabel')}
            autoCapitalize="none"
            autoCorrect={false}
            onChangeText={text => this.setState({ code: text })}
            testID="Landing-TextInput"
            style={{ textAlign: 'center', maxWidth: '100%' }}
            returnKeyType="done"
            onSubmitEditing={submit}
            wait={0}
            value={code}
          />
          <Button
            variant="inlineLg"
            onPress={submit}
            title={translate('Landing.submit')}
            testID="Landing-Button"
          />
          <Button
            variant="inlineMd"
            onPress={() => {
              mediaActions.releaseSound();
              mediaActions.unloadScreenNarration();
              navigationService.navigate('LandingNoKey');
            }}
            title={translate('Landing.noKeyButton')}
            style={{ marginTop: 40, backgroundColor: 'transparent', shadowOpacity: 0, borderWidth: 1, borderColor: 'white' }}
          />
          <div style={{ fontSize: '0.66rem', color: 'white', textAlign: 'center', maxWidth: '500px' }}>
            Copyright © People Designs, Inc, and Copyright © The University of
            Texas Southwestern Medical Center and Southern Methodist University,
            2017. All rights reserved.&nbsp;
            <u
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
              onKeyPress={() => {
                mediaActions.releaseSound();
                mediaActions.unloadScreenNarration();
                navigationService.navigate('Terms');
              }}
              onClick={() => {
                mediaActions.releaseSound();
                mediaActions.unloadScreenNarration();
                navigationService.navigate('Terms');
              }}
            >
              Additional information »
            </u>
          </div>
        </FullScreen>
      </View>
    );
  }
}

Landing.propTypes = {
  authActions: PropTypes.object.isRequired,
  error: PropTypes.object,
  isTablet: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

Landing.defaultProps = {
  error: null,
};

const mapStateToProps = state => ({
  error: state.auth.error,
  isTablet: getDimensions(state).isTablet,
  translate: (key, p, o) => getTranslate(state.locale)(key, p, o),
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActionCreators, dispatch),
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(Landing),
  {
    audio,
    localize,
  },
);
