import * as types from '~actions/types';

const initialState = {
  isPlayingRecording: false,
  isRecording: false,
  sound: null,
};

export default function recorderReducer(state = initialState, action) {
  switch (action.type) {
    case types.START_RECORDING_SUCCESS: {
      return {
        ...state,
        isRecording: true,
      };
    }
    case types.START_RECORDING_ERROR: {
      // eslint-disable-next-line
      console.log(action);
      return state;
    }

    case types.STOP_RECORDING_SUCCESS: {
      return {
        ...state,
        isRecording: false,
      };
    }

    case types.STOP_RECORDING_ERROR: {
      if (action.payload.name === 'InvalidStateError') {
        // element was already stopped
        return {
          ...state,
          isRecording: false,
        };
      }
      return state;
    }

    case types.START_RECORDING_PLAYBACK_SUCCESS: {
      const { sound } = action.payload;
      return {
        ...state,
        sound,
        isPlayingRecording: true,
      };
    }

    case types.STOP_RECORDING_PLAYBACK_SUCCESS: {
      return {
        ...state,
        sound: null,
        isPlayingRecording: false,
      };
    }

    default: {
      return state;
    }
  }
}
