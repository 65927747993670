export default {
  body: `
    ¿Cómo pueden las personas que se vacunan ayudar a su comunidad a regresar
    al trabajo y a la escuela?
  `,
  topic: `
    Cómo pueden las personas que se vacunan ayudar a mi comunidad a regresar
    al trabajo y a la escuela.
  `,
};
