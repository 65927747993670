import { oneLine } from 'common-tags';

import components from '~components/localize';
import screens from '~screens/localize';

export const LANGUAGE_EN = 'en';
export const LANGUAGE_ES = 'es';

export const languages = [LANGUAGE_EN, LANGUAGE_ES];

const localize = languages.reduce(
  (output, lang) => Object.assign(output, {
    [lang]: {
      translation: Object.entries({ ...components, ...screens }).reduce(
        (o, [k, v]) => Object.assign(o, {
          [k]: Object.entries(v[lang]).reduce(
            (oo, [kk, vv]) => {
              switch (typeof vv) {
                case 'string':
                  return Object.assign(oo, {
                    [kk]: oneLine(vv),
                  });
                case 'object':
                  if (Object.keys(vv).length === 0) {
                    return oo;
                  }
                  return Object.assign(oo, {
                    [kk]: { ...Object.assign(
                      ...Object.entries(vv).map(([kkk, vvv]) => ({ [kkk]: oneLine(vvv) })),
                    ) },
                  });
                default:
                  return oo;
              }
            },
            {},
          ),
        }),
        {},
      ),
    },
  }),
  {},
);

export default localize;

export const dictionary = Object.assign(
  {},
  ...Object.keys(localize).map(lang => ({ [lang]: localize[lang].translation })),
);
