import React from 'react';
import PropTypes from 'prop-types';
import { Animated } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import * as localize from './localize';

import { mediaActionCreators, sessionActionCreators } from '~actions';
import { navigationService } from '~services';
import Button from '~components/Button';

const opacity = new Animated.Value(0.2);

const action = ({ isFooterHomeEnabled, mediaActions, sessionActions }) => {
  if (isFooterHomeEnabled) {
    mediaActions.releaseSound();
    mediaActions.unloadScreenNarration();
    sessionActions.setIsFooterNavEnabled(false);
    navigationService.navigate('HomeRedirect');
  }
};

const HomeButton = ({
  isFooterHomeEnabled,
  mediaActions,
  sessionActions,
  title,
  translate,
  variant,
}) => {
  Animated.timing(
    opacity,
    {
      toValue: isFooterHomeEnabled ? 1.0 : 0.2,
      duration: 250,
    },
  ).start();

  return (
    <Animated.View style={{ opacity }}>
      <Button
        onPress={() => action({ isFooterHomeEnabled, mediaActions, sessionActions })}
        title={title || translate('HomeButton.home')}
        variant={variant}
      />
    </Animated.View>
  );
};

HomeButton.propTypes = {
  isFooterHomeEnabled: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  sessionActions: PropTypes.object.isRequired,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

HomeButton.defaultProps = {
  title: null,
  variant: 'home',
};

const mapStateToProps = state => ({
  isFooterHomeEnabled: state.session.isFooterHomeEnabled,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(HomeButton),
  {
    action,
    localize,
  },
);
