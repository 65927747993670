import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { Text } from '~components';
import RecordingScreen from '~components/RecordingScreen';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as localize from './localize';

const nextScreen = 'ReadinessQuestion3';
const recordingScreenName = 'Reasons';
const sidePanelImage = 'GirlOnBike';

const Reasons = ({ translate }) => (
  <RecordingScreen screenName={recordingScreenName}>
    <Text variant="lead">
      {translate('Reasons.body')}
    </Text>
    <Text variant="lead">
      {translate('Reasons.bodySay')}
    </Text>
  </RecordingScreen>
);

Reasons.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(Reasons),
  {
    audio,
    localize,
    nextScreen,
    recordingScreenName,
    sidePanelImage,
  },
);
