import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as localize from './localize';

const Tour = ({ translate }) => (
  <FullScreen>
    <Text variant="instructions">
      {translate('Tour.instructions')}
    </Text>
    {/* TODO  */}
  </FullScreen>
);

Tour.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(Tour),
  {
    localize,
  },
);
