import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { sessionActionCreators } from '~actions';
import { FullScreen, RadioButtonGroup, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as localize from './localize';

const nextScreen = ({ session: { readinessQuestion1 } }) => (
  readinessQuestion1 ? 'ReadinessQuestion1Acknowledgment' : null
);
const sidePanelImage = 'DiagonalOvalTrees';

const ReadinessQuestion1 = ({ response, sessionActions, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, justifyContent: 'center', maxWidth: 900, alignSelf: 'center' }}>
      <Text variant="lead">
        {translate('ReadinessQuestion1.body')}
      </Text>
      <Text>
        {translate('ReadinessQuestion1.question')}
      </Text>
      <RadioButtonGroup
        selectedValues={[response]}
        onSelect={value => {
          sessionActions.setNextScreen('ReadinessQuestion1Acknowledgment');
          sessionActions.setReadinessQuestion1(value);
        }}
        options={[
          {
            value: '1',
            label: translate('ReadinessQuestion1.answer1'),
          },
          {
            value: '2',
            label: translate('ReadinessQuestion1.answer2'),
          },
          {
            value: '3',
            label: translate('ReadinessQuestion1.answer3'),
          },
          {
            value: '4',
            label: translate('ReadinessQuestion1.answer4'),
          },
        ]}
      />
    </View>
    <View style={{ height: 90, alignSelf: 'center' }}>
      <Text variant="instruction">
        {translate('ReadinessQuestion1.instruction')}
      </Text>
    </View>
  </FullScreen>
);

ReadinessQuestion1.propTypes = {
  response: PropTypes.string.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  response: state.session.readinessQuestion1,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(ReadinessQuestion1),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
