import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { getTranslate } from 'react-localize-redux';

import { mediaActionCreators } from '~actions';
import { FullScreen, Video as VideoComponent, Text } from '~components';

import * as localize from './localize';

const nextScreen = 'ReadinessQuestion2';
const sidePanelImage = 'DiagonalOvalTrees';

const Video = ({
  language,
  navigation: { isFocused },
  translate,
}) => (
  <FullScreen style={{ paddingHorizontal: 25 }}>
    <View style={{ width: '100%', maxWidth: 1000, alignSelf: 'center' }}>
      <Text>
        {translate('Video.instruction')}
      </Text>
      {isFocused() && (
        <VideoComponent
          language={language}
        />
      )}
      <View style={{ height: 90, alignSelf: 'center' }}>
        <Text variant="instruction">
          {translate('Video.done')}
        </Text>
      </View>
    </View>
  </FullScreen>
);

Video.propTypes = {
  language: PropTypes.string.isRequired,
  navigation: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  language: state.session.language,
  translate: key => getTranslate(state.locale)(key),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(Video),
  {
    hasVideo: true,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
