import { NavigationActions } from 'react-navigation';

import _resume from './resume';

let navigator;

const setTopLevelNavigator = value => {
  if (!navigator) {
    navigator = value._navigation;
  }
};

const navigate = routeName => {
  navigator.dispatch(
    NavigationActions.navigate({ routeName }),
  );
};

const back = () => {
  navigator.dispatch(
    NavigationActions.back(),
  );
};

const resume = storedRoutes => _resume(navigator, storedRoutes);

export default {
  back,
  navigate,
  resume,
  setTopLevelNavigator,
};
