import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { getTranslate } from 'react-localize-redux';

import * as localize from './localize';

import { mediaActionCreators } from '~actions';
import { Button, FullScreen, Video, Text } from '~components';
import { navigationService } from '~services';

const LandingVideo = ({
  language,
  translate,
}) => (
  <FullScreen style={{ paddingHorizontal: 25 }}>
    <View style={{ width: '100%', maxWidth: 1000, alignSelf: 'center' }}>
      <Text>
        {translate('LandingVideo.instruction')}
      </Text>
      <Video
        language={language}
      />
      <View style={{ height: 150, alignSelf: 'center', paddingTop: 15 }}>
        <Button
          variant="inlineMd"
          onPress={() => {
            navigationService.navigate('Landing');
          }}
          title={translate('BackButton.done')}
        />
        <Text variant="instruction">
          {translate('LandingVideo.done')}
        </Text>
      </View>
    </View>
  </FullScreen>
);

LandingVideo.propTypes = {
  language: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  language: state.session.language,
  translate: key => getTranslate(state.locale)(key),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(LandingVideo),
  {
    hasVideo: true,
    localize,
  },
);
