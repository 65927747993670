import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { INIT } from '@react-navigation/core/src/NavigationActions';

import { sessionActionCreators } from '~actions';
import { RotatingSidePanel } from '~components';
import StackNavigator from '~navigators/StackNavigator';
import { getDimensions } from '~selectors';
import store from '~store';

import * as screens from './screens';

const sessionActions = bindActionCreators(sessionActionCreators, store.dispatch);

const Navigator = StackNavigator({
  initialRouteName: 'ReadinessQuestion1',
  screens,
});

const IsViewingContent = ({ dimensions: { isLandscape, isTablet }, navigation }) => (
  <View style={{ flex: 1, flexDirection: 'row' }}>
    {
      isTablet && isLandscape && <RotatingSidePanel />
    }
    <Navigator
      navigation={navigation}
    />
  </View>
);

IsViewingContent.propTypes = {
  dimensions: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

IsViewingContent.router = {
  ...Navigator.router,
  getStateForAction: (action, state) => {
    const newState = Navigator.router.getStateForAction(
      { ...action, ...{ params: { index: state && state.index } } },
      state,
    );
    if (action.type === INIT || (newState && !newState.routeName)) {
      return newState;
    }
    const areRoutesStacked = newState && newState.routes && newState.routes.length > 1;
    sessionActions.setIsFooterBackEnabled(Boolean(areRoutesStacked));
    // if (areRoutesStacked) {
    sessionActions.storeRoutes(newState.routes);
    // }
    // if (newState && newState.routes) {
    //   newState.routes = newState.routes.slice(-2);
    //   newState.index = newState.routes.length - 1;
    // }
    return newState;
  },
};

const mapStateToProps = state => ({
  dimensions: getDimensions(state),
});

export default connect(mapStateToProps)(IsViewingContent);
