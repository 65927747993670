import {
  SET_PROPERTY,
  CLEAR_PROPERTY,
} from './types';

export const setProperty = property => ({
  type: SET_PROPERTY,
  property,
});
export const clearProperty = () => ({
  type: CLEAR_PROPERTY,
});
