export default {
  alreadyHasAppointment: `
    ¡Excelente! No olvide mencionar la vacuna contra el coronavirus cuando se
    presente a su cita. Asimismo, menciónelo a su proveedor de servicios de salud.
  `,
  no: `
    ¡Agende una cita lo antes posible!
  `,
  phoneReferral: `
    Llamar al \${vaccine_referral_phone}.
  `,
  urlReferral: `
    Visita
  `,
  noReferralProvider: `
    Si tiene un proveedor de servicios de salud habitual, llame a su consulta
    para agendar una cita.
  `,
  noReferralClinic: `
    La mayoría de los departamentos de salud de las ciudades y los condados
    pueden administrar la vacuna o remitirlo al proveedor más cercano.
  `,
  noReferralPharm: `
    Muchas farmacias pueden administrar la vacuna.
  `,
  moreInformation: `
    Más información
  `,
  // https://www.naccho.org/membership/lhd-directory
  healthDepartments: `
    Encontrar departamentos públicos de salud
  `,
  // https://pharmacy-locator.envolverx.com/
  // https://vaccinefinder.org/search/
  pharmacies: `
    Encontrar farmacias
  `,
};
