export default {
  body: `
    ¿Cómo puede su vacunación ayudar a otros en su comunidad a mantenerse
    sanos o a vacunarse ellos mismos?
  `,
  topic: `
    Cómo el hecho de vacunarse puede ayudar a otras personas de mi comunidad
    a mantenerse sanas o a que ellas también se vacunen.
  `,
};
