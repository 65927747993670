import EStyleSheet from 'react-native-extended-stylesheet';
import colors from './colors';

const initialize = () => EStyleSheet.build({
  $rem: 14,
  '@media (min-width: 768)': {
    $rem: 16,
  },
  $bodyFontSize: '1.5rem',
  $borderRadius: '0.5rem',
  $buttonFontSize: '2.5rem',
  $fontFamily: 'Roboto',
  $introBodyFontSize: '1.5rem',
  $leadFontSize: '1.75rem',
  $smallFontSize: '1.25rem',
  $posterFontSize: '2.5rem',
  ...Object.assign(...Object.entries(colors).map(([k, v]) => ({ [`$${k}`]: v }))),
});

const reload = () => {
  EStyleSheet.clearCache();
  EStyleSheet.build();
};

export {
  initialize,
  reload,
  colors,
};
