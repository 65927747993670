module.exports = {
  putty: '#eae6cd',
  buff: '#f0ebc9',
  gold: '#ddb64e',
  orange: '#f7794c',
  orange_translucent: '#f7794c33',
  red: '#f64c4d',
  rust: '#c6552a',
  teal: '#489aa4',
  aqua: '#7fe6dd',
  ink: '#35555d',
  ink_translucent: '#35555dcc',
  mint: '#b4d7c5',
  jade: '#7ed0a7',
  green: '#3ca347',
  cherry: '#bd252c',
  apricot: '#cb9758',
};
