import qs from 'qs';

export default () => {
  const { language, ...rest } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (language) {
    window.history.replaceState(
      {},
      window.location.title,
      window.location.href.replace(
        window.location.search,
        qs.stringify(rest, { addQueryPrefix: true }),
      ),
    );
    return language;
  }
  return navigator.language.substring(0, 2);
};
