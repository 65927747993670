export default {
  readyBody: `
    ¡Excelente! Esperamos que el siguiente vídeo le ayude a entender mejor por
    qué la vacuna contra el coronavirus es importante y cómo puede vacunarse.
  `,
  notReadyBody: `
    Está bien. Esperamos que el siguiente vídeo le ayude a saber más sobre
    la vacuna contra el coronavirus.
  `,
  instruction: `
    Pulse el botón <b>Continuar</b> para ver el video.
  `,
};
