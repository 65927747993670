export default {
  body: `
    Algunas personas mencionan sus inquietudes respecto a la vacuna contra el
    coronavirus. ¿Qué pueden decir los médicos, farmacéuticos u otros
    profesionales de la salud para calmar sus inquietudes?
  `,
  topic: `
    Lo que pueden decir los médicos, farmacéuticos u otros profesionales de la 
    salud para calmar las inquietudes respecto a la vacuna contra el coronavirus.
  `,
};
