import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, ReportButton, ResponsiveStacker, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const sidePanelImage = 'BoyJumpingIntoPool';

const ActionPlanClosing = ({ translate }) => (
  <FullScreen>
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 900, alignSelf: 'center' }}>
      <ResponsiveStacker>
        <View
          style={{
            flexBasis: 300,
            flexGrow: 0,
            maxWidth: '100%',
            aspectRatio: 1,
          }}
        >
          <Image
            source={images.DefaultSpotImage}
            style={{
              flexBasis: 280,
              maxWidth: '100%',
            }}
            resizeMode="contain"
          />
        </View>
        <View>
          <Text variant="poster" style={{ textAlign: 'center' }}>
            {translate('ActionPlanClosing.headline')}
          </Text>
          <Text style={{ textAlign: 'center' }}>
            {translate('ActionPlanClosing.body')}
          </Text>
          <ReportButton
            variant={['report', 'inlineMd']}
            title={translate('ActionPlanClosing.summaryButton')}
          />
        </View>
      </ResponsiveStacker>
    </View>
  </FullScreen>
);

ActionPlanClosing.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(ActionPlanClosing),
  {
    audio,
    isClosing: true,
    localize,
    sidePanelImage,
  },
);
