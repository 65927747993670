import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Video = ({ language, url, videoPaths }) => (
  <div className="embed-responsive embed-responsive-16by9 rounded mb-5">
    <iframe
      src={url || videoPaths[language]}
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      title="video"
    />
  </div>
);

Video.propTypes = {
  language: PropTypes.string.isRequired,
  url: PropTypes.string,
  videoPaths: PropTypes.object.isRequired,
};

Video.defaultProps = {
  url: null,
};

const mapStateToProps = state => ({
  videoPaths: state.property.videoPaths,
});

export default connect(mapStateToProps)(Video);
