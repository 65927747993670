export default {
  welcomeTitle: `
    Welcome to My\u00a0Move!
  `,
  body: `
    To get started, please enter your\u00a0access\u00a0key.
  `,
  keyExplanation: `
    The access key does not identify you. It only identifies the organization
    that referred you.
  `,
  submit: `
    Submit
  `,
  codeLabel: `
    Access Key
  `,
  noKeyButton: `
    I Do Not Have a\u00a0Key
  `,
  changeLanguageButton: `
    Español
  `,
  errors: {
    network: `
      We’re sorry, there was a network error. Please try again later.
    `,
    code: `
      We’re sorry, we could not find the access key you entered. Please try
      again.
    `,
    unknown: `
      We’re sorry, there was an error. Please try again later.
    `,
  },
};
