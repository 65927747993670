import auth from './authLogic';
import boot from './bootLogic';
import media from './mediaLogic';
import session from './sessionLogic';
import recorder from './recorderLogic';

export default [
  ...auth,
  ...boot,
  ...media,
  ...session,
  ...recorder,
];
