import { createSelector } from 'reselect';
import { getActiveLanguage } from 'react-localize-redux';

const getParameters = state => {
  const { locale, property } = state;
  const { code } = getActiveLanguage(locale);
  const parameters = Object.fromEntries(Object.entries(property).map(([k, v]) => [
    k,
    v[code] || (v._fallbackDefaults ? v.en : null),
  ]));
  return parameters;
};

export default createSelector(
  [getParameters],
  parameters => parameters,
);
