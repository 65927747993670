import * as types from '~actions/types';

const initialState = {
  isFetching: false,
  error: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_AUTH: {
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    }

    case types.FETCH_AUTH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.FETCH_AUTH_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
