import {
  SET_DIMENSIONS,
  SET_IS_TABLET,
  SET_ORIENTATION,
  SET_VERSION_STRING,
} from './types';

export const setOrientation = orientation => ({
  type: SET_ORIENTATION,
  orientation,
});

export const setIsTablet = isTablet => ({
  type: SET_IS_TABLET,
  isTablet,
});

export const setDimensions = ({ width, height }) => ({
  type: SET_DIMENSIONS,
  width,
  height,
});

export const setVersionString = versionString => ({
  type: SET_VERSION_STRING,
  versionString,
});
