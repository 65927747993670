import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Animated } from 'react-native';

import { mediaActionCreators, sessionActionCreators } from '~actions';
import Button from '~components/Button';
import { navigationService } from '~services';

import * as localize from './localize';

const OPAQUE = 1;
const TRANSLUCENT = 0.5;

const opacity = new Animated.Value(TRANSLUCENT);

class NextButton extends React.Component {
  componentDidUpdate(prevProps) {
    const { nextScreen } = this.props;
    if ((prevProps.nextScreen === null) || prevProps.nextScreen !== nextScreen) {
      Animated.timing(
        opacity,
        {
          toValue: nextScreen ? OPAQUE : TRANSLUCENT,
          duration: 500,
        },
      ).start();
    }
  }

  render() {
    const { mediaActions, nextScreen, sessionActions, translate } = this.props;

    return (
      <Animated.View style={{ opacity }}>
        <Button
          onPress={() => {
            if (nextScreen) {
              mediaActions.releaseSound();
              mediaActions.unloadScreenNarration();
              sessionActions.setActiveRecordingScreenName(null);
            }
            navigationService.navigate(nextScreen);
          }}
          variant="nav"
          title={translate('NextButton.forward')}
        />
      </Animated.View>
    );
  }
}

NextButton.propTypes = {
  mediaActions: PropTypes.object.isRequired,
  nextScreen: PropTypes.string,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

NextButton.defaultProps = {
  nextScreen: null,
};

const mapStateToProps = state => ({
  nextScreen: state.session.nextScreen,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(NextButton),
  {
    localize,
  },
);
