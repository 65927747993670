import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import RadioButton from '~components/RadioButton';

const RadioButtonGroup = ({ onSelect, options, selectedValues, variant }) => (
  <View>
    {
      options.map(o => (
        <RadioButton
          key={o.value}
          value={o.value}
          onPress={() => onSelect(o.value)}
          isSelected={selectedValues.includes(o.value)}
          variant={variant}
        >
          {o.label}
        </RadioButton>
      ))
    }
  </View>
);

RadioButtonGroup.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  variant: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  variant: 'default',
};

export default RadioButtonGroup;
