export default {
  body: `
    My Move gives information about the coronavirus vaccine.
  `,
  question: `
    Which statement best describes your thoughts about getting the coronavirus vaccine?
  `,
  answer1: `
    I have never thought about getting the coronavirus vaccine.
  `,
  answer2: `
    I am undecided about getting the coronavirus vaccine.
  `,
  answer3: `
    I do NOT want to get the coronavirus vaccine.
  `,
  answer4: `
    I DO want to get the coronavirus vaccine.
  `,
  instruction: `
    Click the button that best describes your thoughts.
  `,
};
