import {
  LOAD_SCREEN_NARRATION,
  UNLOAD_SCREEN_NARRATION,
  LOAD_SOUND,
  PLAY_SOUND,
  PAUSE_SOUND,
  STOP_SOUND,
  RELEASE_SOUND,
  STOP_AUDIO,
  PLAY_AUDIO,
} from './types';

export const loadScreenNarration = screenNarration => ({
  type: LOAD_SCREEN_NARRATION,
  screenNarration,
});

export const unloadScreenNarration = () => ({
  type: UNLOAD_SCREEN_NARRATION,
});

export const loadSound = onEnd => ({
  type: LOAD_SOUND,
  onEnd,
});

export const playSound = () => ({
  type: PLAY_SOUND,
});

export const pauseSound = () => ({
  type: PAUSE_SOUND,
});

export const stopSound = () => ({
  type: STOP_SOUND,
});

export const releaseSound = () => ({
  type: RELEASE_SOUND,
});

export const stopAudio = () => ({
  type: STOP_AUDIO,
});

export const playAudio = () => ({
  type: PLAY_AUDIO,
});
