export default {
  body: `
    Getting a vaccine may sound simple, but it is important that you make a plan
    so it does not slip through the cracks.
  `,
  question: `
    Do you already have a healthcare appointment scheduled where you will be
    able to get the coronavirus vaccine?
  `,
  yes: `
    Yes
  `,
  no: `
    No
  `,
};
