export default {
  body: `
    To help you discuss with your health care provider, choose your remaining
    concerns about the coronavirus vaccine from the list here.
  `,
  concerns: {
    A: `
      I do not think I am at risk of getting COVID-19.
    `,
    B: `
      I do not think I am at risk of getting sick if I get COVID-19.
    `,
    C: `
      I have concerns about vaccine safety.
    `,
    D: `
      I have concerns about vaccine side effects.
    `,
    E: `
      I think my health condition or age might make getting the vaccine risky for me.
    `,
    F: `
      I think the vaccine is too new and I want to wait and see.
    `,
    G: `
      I do not trust the way the vaccine was developed or is being delivered.
    `,
    H: `
      My provider has not recommended the coronavirus vaccine.
    `,
    I: `
      I have difficulty making or getting to a vaccine appointment.
    `,
    J: `
      The coronavirus vaccine is not required.
    `,
    K: `
      Another reason not listed here.
    `,
  },
};
