import { createLogic } from 'redux-logic';

import { mediaActionCreators, propertyActionCreators, sessionActionCreators } from '~actions';
import {
  BOOT,
  FETCH_AUTH,
  FETCH_AUTH_SUCCESS,
  FETCH_AUTH_ERROR,
  RESET_APP,
} from '~actions/types';
import { authApi, storageApi } from '~api';
import { configService, navigationService } from '~services';

const fetchAuthLogic = createLogic({
  type: FETCH_AUTH,
  latest: true,
  async process({ action }, dispatch, done) {
    const { code } = action;
    try {
      const property = await authApi.getProperty(code);
      await storageApi.storeProperty(property);
      navigationService.navigate('isAuthenticated');
      dispatch(sessionActionCreators.checkForSoloSession());
      dispatch(propertyActionCreators.setProperty(property));
      dispatch({ type: FETCH_AUTH_SUCCESS });
      done();
    } catch (payload) {
      dispatch({ type: FETCH_AUTH_ERROR, payload });
      done();
    }
  },
});

const resetAppLogic = createLogic({
  type: RESET_APP,
  async process(_, dispatch, done) {
    dispatch(mediaActionCreators.releaseSound());
    try {
      await storageApi.deleteAll();
    } catch (e) {
      // eslint-disable-next-line
      console.log(e.message);
    }
    if (typeof document !== 'undefined') {
      window.location = configService.getConfigValue('WEB_URL');
    } else {
      navigationService.navigate('Landing');
      dispatch(propertyActionCreators.clearProperty());
      dispatch({
        type: BOOT,
      });
    }
    done();
  },
});

export default [
  fetchAuthLogic,
  resetAppLogic,
];

// bust
