import { NavigationActions, StackActions } from 'react-navigation';

export default (navigator, storedRoutes) => {
  navigator.dispatch(NavigationActions.navigate(storedRoutes[storedRoutes.length - 1]));
  setTimeout(
    () => navigator.dispatch(StackActions.reset({
      index: storedRoutes.length - 1,
      key: undefined,
      actions: storedRoutes.map(({ routeName }) => NavigationActions.navigate({ routeName })),
    })),
    1,
  );
};
