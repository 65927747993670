import * as en from './en';
import * as es from './es';

const narration = { en, es };

export default ({ session: { language, readinessQuestion3 } }) => {
  switch (readinessQuestion3) {
    case '3':
      return narration[language]?._1;
    case '1':
    default:
      return narration[language]?._2;
    case '2':
      return narration[language]?._3;
  }
};
