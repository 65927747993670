import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, ViewPropTypes } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { withNavigation } from 'react-navigation';

import { getDimensions } from '~selectors';
import { colors } from '~styles';

const styles = EStyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: 40,
    paddingTop: 40,
    paddingBottom: 100,
  },
  containerTablet: {
    paddingBottom: 20,
  },
});

class FullScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: undefined };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.setState({
      index: navigation.getParam('index'),
    });
  }

  shouldComponentUpdate() {
    const { navigation: { dangerouslyGetParent } } = this.props;
    const { index } = this.state;
    const { state: parentState } = dangerouslyGetParent() || { state: null };
    if (!index) {
      return true;
    }
    if (!parentState) {
      return true;
    }
    return (parentState.index - 4 < index);
  }

  render() {
    const {
      backgroundColor,
      children,
      isTablet,
      navigation: { dangerouslyGetParent },
      style,
    } = this.props;
    const { index } = this.state;
    const { state: parentState } = dangerouslyGetParent() || { state: null };
    const shouldRender = (!index || !parentState) || (parentState.index - 3 < index);
    const backgroundColorStyle = {
      backgroundColor: colors[backgroundColor],
    };
    return shouldRender && (
      <View style={{ flexGrow: 1, flexShrink: 1, overflowY: 'auto' }}>
        <View
          style={[
            styles.container,
            isTablet && styles.containerTablet,
            backgroundColorStyle,
            style,
          ]}
        >
          {children}
        </View>
      </View>
    );
  }
}

FullScreen.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  isTablet: PropTypes.bool.isRequired,
  navigation: PropTypes.object.isRequired,
  style: ViewPropTypes ? ViewPropTypes.style : PropTypes.object,
};

FullScreen.defaultProps = {
  backgroundColor: 'teal',
  children: null,
  style: null,
};

const mapStateToProps = getDimensions;

export default withNavigation(connect(mapStateToProps)(FullScreen));
