import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { View } from 'react-native';

import { Button, FullScreen, Text } from '~components';
import { navigationService } from '~services';

const Terms = ({ translate }) => (
  <View style={{ flex: 1, flexDirection: 'row' }}>
    <FullScreen>
      <Text>
        This software constitutes an unpublished work and contains valuable
        trade secrets and proprietary information belonging to UT SOUTHWESTERN
        and SMU. None of the foregoing material may be copied, duplicated or
        disclosed without the express written permission of UT SOUTHWESTERN AND
        SMU. UT SOUTHWESTERN AND SMU EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES
        CONCERNING THIS SOFTWARE, INCLUDING ANY WARRANTIES OF MERCHANTABILITY
        AND/OR FITNESS FOR ANY PARTICULAR PURPOSE, AND WARRANTIES OF
        PERFORMANCE, AND ANY WARRANTY THAT MIGHT OTHERWISE ARISE FROM COURSE OF
        DEALING OR USAGE OF TRADE. NO WARRANTY IS EITHER EXPRESS OR IMPLIED WITH
        RESPECT TO THE USE OF THE SOFTWARE. UNDER NO CIRCUMSTANCES SHALL UT
        SOUTHWESTERN OR SMU BE LIABLE FOR INCIDENTAL, SPECIAL, INDIRECT, DIRECT
        OR CONSEQUENTIAL DAMAGES OR LOSS OF PROFITS, INTERRUPTION OF BUSINESS,
        OR RELATED EXPENSES WHICH MAY ARISE FROM USE OF SOFTWARE, INCLUDING BUT
        NOT LIMITED TO THOSE RESULTING FROM DEFECTS IN SOFTWARE, OR LOSS OR
        INACCURACY OF DATA OF ANY KIND.
      </Text>
      <Button
        variant="inlineMd"
        onPress={() => {
          navigationService.back();
        }}
        style={{ marginBottom: 20 }}
        title={translate('BackButton.back')}
      />
    </FullScreen>
  </View>
);

Terms.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key),
});

export default connect(mapStateToProps)(Terms);
