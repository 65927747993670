import uuidGenerator from 'uuid/v5';

import { storageApi } from '~api';

const unlockAudioContext = context => {
  if (context.state === 'suspended') {
    const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
    const unlock = () => {
      events.forEach(e => document.body.removeEventListener(e, unlock));
      context.resume();
    };

    events.forEach(e => document.body.addEventListener(e, unlock, false));
  }
};

let audioContext;
let gainNode;
if (window.AudioContext || window.webkitAudioContext) {
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  gainNode = audioContext.createGain();
  gainNode.gain.value = 0.5;

  unlockAudioContext(audioContext);
}

let isDenied = false;

class RecorderApi {
  static PERMISSION_UNAVAILABLE = 'unavailable';

  static PERMISSION_AUTHORIZED = 'authorized';

  static PERMISSION_DENIED = 'denied';

  static PERMISSION_RESTRICTED = 'restricted';

  static PERMISSION_UNDETERMINED = 'undetermined';

  static generateFilename(screenName) {
    const uuid = uuidGenerator(screenName, 'b0ca09a5-9cb0-453e-aee9-edac937c5d94');
    return `${uuid}.aac`;
  }

  static async startRecordingPlayback(filename, onended) {
    if (audioContext) {
      const blob = await storageApi.retrieveRecording(filename);

      const request = new XMLHttpRequest();
      request.open(
        'GET',
        URL.createObjectURL(blob),
        true,
      );
      request.responseType = 'arraybuffer';
      request.onload = () => audioContext.decodeAudioData(
        request.response,
        buffer => {
          const bufferSource = audioContext.createBufferSource();
          bufferSource.buffer = buffer;
          gainNode.connect(audioContext.destination);
          bufferSource.connect(gainNode);
          bufferSource.onended = onended;
          bufferSource.start();
        },
      );
      request.send();
    }
  }

  static async stopRecordingPlayback() {
    gainNode.disconnect();
  }

  static async checkMicrophoneAccess() {
    if (isDenied) {
      return this.PERMISSION_DENIED;
    }
    if (!navigator.mediaDevices) {
      return this.PERMISSION_UNAVAILABLE;
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    if (devices.some(device => device.kind === 'audioinput' && device.label !== '')) {
      return this.PERMISSION_AUTHORIZED;
    }
    return this.PERMISSION_UNDETERMINED;
  }

  static async requestMicrophoneAccess() {
    try {
      const userMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
      return userMedia;
    } catch (e) {
      isDenied = true;
      return null;
    }
  }

  static async openSettings() {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }
}

export default RecorderApi;
