export default {
  question: `
    Después de ver el vídeo, ¿qué afirmación describe mejor su opinión acerca
    de aplicarse la vacuna contra el coronavirus?
  `,
  answer1: `
    Estoy indeciso sobre la posibilidad de vacunarme contra el coronavirus.
  `,
  answer2: `
    NO quiero vacunarme contra el coronavirus.
  `,
  answer3: `
    Yo SÍ quiero vacunarme contra el coronavirus.
  `,
  instruction: `
    Pulse el botón de la oración que mejor describe su opinión.
  `,
};
