import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, ResponsiveStacker, Text } from '~components';
import { getDimensions, getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const nextScreen = 'Question1';
const sidePanelImage = 'FlattopDudeArmsCrossedConcerned';

const AnsweringInstructions = ({ isTablet, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 900, alignSelf: 'center' }}>
      <ResponsiveStacker>
        {
          isTablet
            ? (
              <View
                style={{
                  flexBasis: 300,
                  flexGrow: 0,
                  maxWidth: '100%',
                  aspectRatio: 1,
                }}
              >
                <Image
                  source={images.DefaultSpotImage}
                  style={{
                    flexBasis: 300,
                    maxWidth: '100%',
                  }}
                  resizeMode="contain"
                />
              </View>
            )
            : (
              <View
                style={{
                  flexBasis: 240,
                  flexGrow: 0,
                  maxWidth: '100%',
                  aspectRatio: 1,
                }}
              >
                <Image
                  source={images.DefaultSpotImage}
                  style={{
                    flexBasis: 200,
                    maxWidth: '100%',
                  }}
                  resizeMode="contain"
                />
              </View>
            )
          }
        <Text variant="lead">
          {translate('AnsweringInstructions.body')}
        </Text>
      </ResponsiveStacker>
    </View>
    <View style={{ height: 90, alignSelf: 'center' }}>
      <Text variant="instruction">
        {translate('AnsweringInstructions.instruction')}
      </Text>
    </View>
  </FullScreen>
);

AnsweringInstructions.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isTablet: getDimensions(state).isTablet,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(AnsweringInstructions),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
