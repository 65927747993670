import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { BOOT } from '~actions/types';
import { beaconMiddleware, logicMiddleware } from '~middleware';
import reducers from '~reducers';

const store = createStore(
  combineReducers(reducers),
  composeWithDevTools(applyMiddleware(beaconMiddleware, logicMiddleware)),
);

store.dispatch({ type: BOOT });

export default store;
