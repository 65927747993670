export default {
  body: `
    Next, you will be asked questions based on the three topics you selected.
    After each question, you will be able to record your voice as you answer.
  `,
  unavailable: `
    Next, you will be asked questions based on the three topics you selected.
    Your browser does not support audio recording, but you should still speak
    your answers out loud.
  `,
  microphone: `
    My Move needs your permission to access your microphone when you choose to
    record your voice. Your recordings will NOT leave your computer and are not
    shared with My Move, your health care provider, or anyone else.
  `,
  microphoneButton: `
    Allow microphone access
  `,
  denied: `
    My Move is not able to access your microphone. Your browser settings may
    be blocking it. You can still continue to use My Move without recording your voice.
  `,
  authorized: `
    Please click the <b>Continue</b> button.
  `,
};
