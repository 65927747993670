export default {
  body: `
    Gracias por haber dedicado parte de su tiempo para informarse sobre la
    vacuna contra el coronavirus.
  `,
  learnMore: `
    Para obtener más información sobre el coronavirus y la vacuna, haga clic en el botón <b>Más información</b>.
  `,
  learnMoreButton: `
    Más información
  `,
  summary: `
    Si desea ver el resumen de lo que ha tratado en My Move, haga clic en el
    botón <b>Resumen</b>. Si lo desea, puede compartir su resumen con su proveedor
    de servicios de salud.
  `,
  done: `
    Pulse el botón <b>Terminado</b> si ha terminado.
  `,
  doneButton: `
    Terminado
  `,
  summaryButton: `
    Resumen
  `,
};
