import * as en from './en';
import * as es from './es';

const narration = { en, es };

export default {
  list: [0, 1, 2].map(i => ({ session: { language, selectedQuestions } }) => {
    switch (selectedQuestions[i]) {
      case 'A':
        return narration[language]?._1;
      case 'B':
        return narration[language]?._2;
      case 'C':
        return narration[language]?._3;
      case 'D':
        return narration[language]?._4;
      case 'E':
        return narration[language]?._5;
      case 'F':
        return narration[language]?._6;
      case 'G':
        return narration[language]?._7;
      case 'H':
        return narration[language]?._8;
      default:
        return null;
    }
  }),
};
