import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';

import { mediaActionCreators } from '~actions';
import { recorderApi } from '~api';
import { Button, FullScreen, ResponsiveStacker, Text } from '~components';
import { getDimensions, getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const nextScreen = navigator.mediaDevices ? 'RecordingInstructions' : 'AnsweringInstructions';

class QuestionsIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = { permissions: recorderApi.PERMISSION_AUTHORIZED };
  }

  async componentDidMount() {
    this.setState({ permissions: await recorderApi.checkMicrophoneAccess() });
  }

  getRecorderFeedback() {
    const { mediaActions, translate } = this.props;
    const { permissions } = this.state;
    switch (permissions) {
      case recorderApi.PERMISSION_AUTHORIZED:
        return (
          <Text>
            {translate('QuestionsIntro.authorized')}
          </Text>
        );
      case recorderApi.PERMISSION_UNDETERMINED:
        return (
          <Button
            onPress={async () => {
              mediaActions.releaseSound();
              mediaActions.unloadScreenNarration();
              await recorderApi.requestMicrophoneAccess();
              this.setState({ permissions: await recorderApi.checkMicrophoneAccess() });
            }}
            title={translate('QuestionsIntro.microphoneButton')}
          />
        );
      default:
        return (
          <Text style={{ color: '#FFFF99' }}>
            {translate('QuestionsIntro.denied')}
          </Text>
        );
    }
  }

  render() {
    const { isTablet, translate } = this.props;
    const { permissions } = this.state;

    return (
      <FullScreen>
        <View style={{ flex: 1, flexDirection: 'row', maxWidth: 900, alignSelf: 'center' }}>
          <ResponsiveStacker>
            <View>
              {
                permissions === recorderApi.PERMISSION_UNAVAILABLE
                  ? (
                    <Text variant="lead">
                      {translate('QuestionsIntro.unavailable')}
                    </Text>
                  ) : (
                    <>
                      <Text variant="lead">
                        {translate('QuestionsIntro.body')}
                      </Text>
                      <View
                        style={{
                          paddingBottom: 40,
                        }}
                      >
                        {permissions !== recorderApi.PERMISSION_AUTHORIZED && (
                          <Text>
                            {translate('QuestionsIntro.microphone')}
                          </Text>
                        )}
                        {this.getRecorderFeedback()}
                      </View>
                    </>
                  )
              }
            </View>
            {
              isTablet
                ? (
                  <View
                    style={{
                      flexBasis: 300,
                      flexGrow: 0,
                      maxWidth: '100%',
                      aspectRatio: 1,
                    }}
                  >
                    <Image
                      source={images.DefaultSpotImage}
                      style={{
                        flexBasis: 300,
                        height: 600,
                        maxWidth: '100%',
                      }}
                      resizeMode="contain"
                    />
                  </View>
                )
                : (
                  <View />
                )
            }
          </ResponsiveStacker>
        </View>
      </FullScreen>
    );
  }
}

QuestionsIntro.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isTablet: getDimensions(state).isTablet,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(QuestionsIntro),
  {
    audio,
    localize,
    nextScreen,
  },
);
