/* eslint global-require: 0 */

export default {
  ActionPlanClosing: require('./29-ActionPlanClosing/localize'),
  ActionPlanIntro: require('./27-ActionPlanIntro/localize'),
  ActionPlanReferral: require('./28-ActionPlanReferral/localize'),
  AnsweringInstructions: require('./19-AnsweringInstructions/localize'),
  ConcernsClosing: require('./26-ConcernsClosing/localize'),
  ConcernsSelection: require('./25-ConcernsSelection/localize'),
  Home: require('./06-Home/localize'),
  Landing: require('./01-Landing/localize'),
  LandingNoKey: require('./02-LandingNoKey/localize'),
  LandingVideo: require('./03-LandingVideo/localize'),
  Questions: require('./20-Questions/localize'),
  QuestionsIntro: require('./17-QuestionsIntro/localize'),
  QuestionsSelection: require('./16-QuestionsSelection/localize'),
  ReadinessQuestion1: require('./11-ReadinessQuestion1/localize'),
  ReadinessQuestion1Acknowledgment: require('./12-ReadinessQuestion1Acknowledgment/localize'),
  ReadinessQuestion2: require('./14-ReadinessQuestion2/localize'),
  ReadinessQuestion2Acknowledgment: require('./15-ReadinessQuestion2Acknowledgment/localize'),
  ReadinessQuestion3: require('./23-ReadinessQuestion3/localize'),
  ReadinessQuestion3Acknowledgment: require('./24-ReadinessQuestion3Acknowledgment/localize'),
  Reasons: require('./22-Reasons/localize'),
  ReasonsIntro: require('./21-ReasonsIntro/localize'),
  RecordingInstructions: require('./18-RecordingInstructions/localize'),
  Tour: require('./04-Tour/localize'),
  Video: require('./13-Video/localize'),
};
