import { storageApi } from '~api';

let mediaRecorder;
let audioChunks;
let filename;
let resolvePromise;
let resolveStop;

const start = async _filename => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  mediaRecorder = new MediaRecorder(stream);
  audioChunks = [];
  filename = _filename;
  mediaRecorder.addEventListener(
    'dataavailable',
    ({ data }) => audioChunks.push(data),
  );
  mediaRecorder.addEventListener(
    'stop',
    async () => {
      await storageApi.storeRecordingWithFilename(
        new Blob(audioChunks, { type: audioChunks[0].type }),
        filename,
      );
      resolveStop(filename);
    },
  );
  mediaRecorder.start();
};

const stop = async () => {
  resolvePromise = new Promise(resolve => { resolveStop = resolve; });
  mediaRecorder.stop();
  return resolvePromise;
};

export default () => ({
  start,
  stop,
});
