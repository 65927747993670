/* eslint global-require: 0 */

export default {
  BackButton: require('./BackButton/localize'),
  Email: require('./Email/localize'),
  Footer: require('./Footer/localize'),
  HomeButton: require('./HomeButton/localize'),
  NextButton: require('./NextButton/localize'),
  PlayButton: require('./PlayButton/localize'),
  RecordButton: require('./RecordButton/localize'),
};
