import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { getTranslate } from 'react-localize-redux';

import { mediaActionCreators, recorderActionCreators } from '~actions';
import { recorderApi } from '~api';
import { Button, FullScreen, RecordButton, Text } from '~components';

class RecordingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { permissions: recorderApi.PERMISSION_AUTHORIZED };
  }

  async componentDidMount() {
    this.setState({ permissions: await recorderApi.checkMicrophoneAccess() });
  }

  getRecordingInterface() {
    const {
      activeRecordingScreenName,
      isPlayingRecording,
      isRecording,
      mediaActions,
      recorderActions,
      recordings,
      screenName,
      translate,
    } = this.props;
    const { permissions } = this.state;
    switch (permissions) {
      case recorderApi.PERMISSION_AUTHORIZED:
        return (
          <View>
            <RecordButton
              screenName={screenName}
            />
            {
              (activeRecordingScreenName === screenName)
                && recordings[screenName]
                && !isRecording
                && (
                  <Button
                    title={isPlayingRecording ? translate('PlayButton.stopPlayback') : translate('PlayButton.playRecording')}
                    onPress={() => {
                      mediaActions.stopSound();
                      if (isPlayingRecording) {
                        recorderActions.stopRecordingPlayback();
                      } else {
                        recorderActions.startRecordingPlayback(screenName);
                      }
                    }}
                  />
                )
            }
          </View>
        );
      case recorderApi.PERMISSION_UNDETERMINED:
        return (
          <Button
            onPress={async () => {
              await recorderApi.requestMicrophoneAccess();
              this.setState({ permissions: await recorderApi.checkMicrophoneAccess() });
            }}
            title={translate('QuestionsIntro.microphoneButton')}
          />
        );
      case recorderApi.PERMISSION_UNAVAILABLE:
        return (
          <View style={{ maxWidth: 500, alignSelf: 'center', alignItems: 'center' }}>
            <Text variant="lead">
              {translate('RecordButton.denied')}
            </Text>
          </View>
        );
      default:
        return (
          <View style={{ maxWidth: 500, alignSelf: 'center', alignItems: 'center' }}>
            <Text style={{ color: '#FFFF99', textAlign: 'center' }}>
              {translate('RecordButton.deniedNotice')}
            </Text>
            <Text variant="lead">
              {translate('RecordButton.denied')}
            </Text>
          </View>
        );
    }
  }

  render() {
    const { children } = this.props;
    return (
      <FullScreen>
        <View style={{ flex: 1, justifyContent: 'center', maxWidth: 700, alignSelf: 'center' }}>
          {children}
        </View>
        <View style={{ flex: 1, flexBasis: 360, justifyContent: 'flex-start', alignItems: 'center', width: '100%', marginTop: 30 }}>
          {this.getRecordingInterface()}
        </View>
      </FullScreen>
    );
  }
}

RecordingScreen.propTypes = {
  activeRecordingScreenName: PropTypes.string.isRequired,
  children: PropTypes.node,
  isPlayingRecording: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  recorderActions: PropTypes.object.isRequired,
  recordings: PropTypes.object.isRequired,
  screenName: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

RecordingScreen.defaultProps = {
  children: null,
  screenName: null,
};

const mapStateToProps = state => ({
  activeRecordingScreenName: state.session.activeRecordingScreenName || '',
  isPlayingRecording: state.recorder.isPlayingRecording,
  isRecording: state.recorder.isRecording,
  recordings: state.session.recordings,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  recorderActions: bindActionCreators(recorderActionCreators, dispatch),
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordingScreen);
