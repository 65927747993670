export default {
  readyBody: `
    Great! We hope the following video will help you learn more about why the
    coronavirus vaccine is important, and how you can get the vaccine.
  `,
  notReadyBody: `
    Okay. We hope the following video will help you learn more about the
    coronavirus vaccine.
  `,
  instruction: `
    Click the <b>Continue</b> button to watch the video.
  `,
};
