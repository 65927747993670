export default {
  alreadyHasAppointment: `
    Great! Be sure to mention the coronavirus vaccine when you check in for your
    appointment. Also mention it to your health care provider.
  `,
  no: `
    Please make an appointment as soon as you can!
  `,
  // TODO
  phoneReferral: `
    Call \${vaccine_referral_phone}.
  `,
  urlReferral: `
    Visit
  `,
  noReferralProvider: `
    If you have a regular health care provider, call their office
    to make an appointment.
  `,
  noReferralClinic: `
    Most city and county health departments can give the vaccine or direct you to a
    provider near to you.
  `,
  noReferralPharm: `
    Many pharmacies can give the vaccine.
  `,
  moreInformation: `
    More Information
  `,
  // https://www.naccho.org/membership/lhd-directory
  healthDepartments: `
    Find a Health Department
  `,
  // https://pharmacy-locator.envolverx.com/
  pharmacies: `
    Find Pharmacies
  `,
};
