import getRecorder from './getRecorder';
import getDurationOfFile from './getDurationOfFile';

let recorder;

const startRecording = async filename => {
  recorder = getRecorder();
  return recorder.start(filename);
};

const stopRecording = async () => recorder && recorder.stop();

export default {
  startRecording,
  stopRecording,
  getDurationOfFile,
};
