import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { sessionActionCreators } from '~actions';
import { FullScreen, RadioButtonGroup, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as localize from './localize';

const nextScreen = 'ConcernsClosing';
const sidePanelImage = 'DiplomaGirl';

const ConcernsSelection = ({ selectedValues, sessionActions, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, justifyContent: 'center', maxWidth: 900, alignSelf: 'center' }}>
      <Text variant="lead">
        {translate('ConcernsSelection.body')}
      </Text>
      <RadioButtonGroup
        selectedValues={selectedValues}
        onSelect={value => sessionActions.toggleSelectedConcern(value)}
        options={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'].map(concern => ({
          value: concern,
          label: translate(`ConcernsSelection.concerns.${concern}`),
        }))}
        variant="small"
      />
    </View>
  </FullScreen>
);

ConcernsSelection.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedValues: state.session.selectedConcerns,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
  dispatch,
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(ConcernsSelection),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
