import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Text,
  TouchableOpacity,
} from 'react-native';
import { getTranslate } from 'react-localize-redux';
import EStyleSheet from 'react-native-extended-stylesheet';
import throttle from 'lodash.throttle';

import * as localize from './localize';

import { mediaActionCreators, recorderActionCreators } from '~actions';

const styles = EStyleSheet.create({
  button: {
    borderRadius: 10000,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16rem',
    height: '16rem',
    paddingHorizontal: '1rem',
    borderWidth: '1rem',
  },
  buttonIsRecording: {
    backgroundColor: '$cherry',
    borderColor: '$red',
  },
  buttonIsNotRecording: {
    backgroundColor: '$green',
    borderColor: '$jade',
  },
  buttonText: {
    color: 'white',
    fontSize: '$buttonFontSize',
    textAlign: 'center',
    fontFamily: '$fontFamily',
  },
});

class RecordButton extends React.Component {
  handlePress = throttle(
    () => {
      const {
        mediaActions,
        recorder,
        recorderActions,
        screenName,
      } = this.props;

      mediaActions.stopSound();
      if (recorder.isRecording) {
        recorderActions.stopRecording(screenName);
      } else {
        recorderActions.startRecording(screenName);
      }
    },
    1000,
    { leading: true, trailing: false },
  );

  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }

  render() {
    const {
      recorder,
      translate,
    } = this.props;

    return (
      <TouchableOpacity
        onPress={this.handlePress}
        activeOpacity={0.9}
        style={[
          styles.button,
          recorder.isRecording ? styles.buttonIsRecording : styles.buttonIsNotRecording,
        ]}
      >
        <Text
          style={styles.buttonText}
          allowFontScaling={false}
        >
          {
            recorder.isRecording
              ? translate('RecordButton.stopRecording')
              : translate('RecordButton.startRecording')
          }
        </Text>
      </TouchableOpacity>
    );
  }
}

RecordButton.propTypes = {
  mediaActions: PropTypes.object.isRequired,
  recorder: PropTypes.object.isRequired,
  recorderActions: PropTypes.object.isRequired,
  screenName: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recorder: state.recorder,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  recorderActions: bindActionCreators(recorderActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(RecordButton),
  {
    localize,
  },
);
