export default {
  yesBody: `
    Great! Next, you will learn more about how to get the coronavirus vaccine.
  `,
  unsureBody: `
    Since you are unsure, it might be helpful to talk about the vaccine with
    your health care provider. On the next screen, you will have the chance to
    select your concerns.
  `,
  noBody: `
    It looks like you are still not ready to get the coronavirus vaccine. We
    hope My Move has been helpful to you in thinking about the vaccine. We hope
    you discuss this more with your health care provider. On the next screen,
    you will have the chance to select your concerns.
  `,
};
