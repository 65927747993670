import { createLogic } from 'redux-logic';
import { initialize, addTranslationForLanguage } from 'react-localize-redux';
import qs from 'qs';

import { sessionActionCreators, propertyActionCreators } from '~actions';
import { BOOT } from '~actions/types';
import { authApi, storageApi } from '~api';
import { dictionary, languages } from '~localize';
import { authService, navigationService } from '~services';

import getDeviceLanguage from './getDeviceLanguage';
import makeDefaultVideosAvailable from './makeDefaultVideosAvailable';

const bootLogic = createLogic({
  type: BOOT,
  async process(_, dispatch, done) {
    await storageApi.deleteAllSessions();
    await authApi.getToken();
    authService.setAuthorization();
    const defaultLanguage = getDeviceLanguage();

    if (languages.includes(defaultLanguage)) {
      dispatch(initialize(languages, { defaultLanguage }));
      dispatch(sessionActionCreators.setLanguage(defaultLanguage));
    } else {
      dispatch(initialize(languages, { defaultLanguage: languages[0] }));
    }
    languages.forEach(
      language => dispatch(addTranslationForLanguage(dictionary[language], language)),
    );

    const hasExpired = await storageApi.checkHasExpired();
    if (hasExpired) {
      await storageApi.deleteAll();
    }

    const areDefaultVideosAvailable = await storageApi.checkForDefaultVideos();

    if (!areDefaultVideosAvailable) {
      await makeDefaultVideosAvailable();
    }

    try {
      const storedProperty = await storageApi.retrieveProperty();
      if (storedProperty.videoVariant) {
        storedProperty.videoPaths = await storageApi.retrieveVideoVariant(
          storedProperty.videoVariant,
        );
      }
      try {
        dispatch(propertyActionCreators.setProperty(storedProperty));
        const { code, ...rest } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        window.history.replaceState(
          {},
          window.location.title,
          window.location.href.replace(
            window.location.search,
            qs.stringify(rest, { addQueryPrefix: true }),
          ),
        );
        dispatch(sessionActionCreators.checkForSoloSession());
        navigationService.navigate('isAuthenticated');
      } catch (setPropertyError) {
        // eslint-disable-next-line
        console.log(`Cannot set property: ${setPropertyError.message}`);
      }
    } catch (retrievePropertyError) {
      navigationService.navigate('isNotAuthenticated');
    }

    try {
      dispatch(propertyActionCreators.setProperty({
        videoPaths: (await storageApi.retrieveVideoVariant()),
      }));
    } catch (setUpdatedPropertyError) {
      // eslint-disable-next-line
      console.log(`Cannot set updated property: ${setUpdatedPropertyError.message}`);
    }

    done();
  },
});

export default [
  bootLogic,
];
