import { AppRegistry } from 'react-native';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';

import { deviceActionCreators } from '~actions';
import store from '~store';
import {
  initialize as initializeStyles,
} from '~styles';

import App from './App';

const deviceActions = bindActionCreators(deviceActionCreators, store.dispatch);

initializeStyles();

console.disableYellowBox = true; // eslint-disable-line no-console

AppRegistry.registerComponent('My Move', () => App);

AppRegistry.runApplication('My Move', {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

/*
  handle device width
 */
const setDimensions = () => deviceActions.setDimensions({
  width: window.innerWidth,
  height: window.innerHeight,
});

setDimensions();
window.addEventListener('resize', debounce(setDimensions, 500, { leading: true }));
window.addEventListener(
  'click',
  () => {
    window.addEventListener('beforeunload', e => {
      e.preventDefault();
      e.returnValue = '';
    });
  },
  { once: true },
);
