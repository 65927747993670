import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { getTranslate } from 'react-localize-redux';
import EStyleSheet from 'react-native-extended-stylesheet';

import { authActionCreators, mediaActionCreators, sessionActionCreators } from '~actions';
import { Button, FullScreen, ReportButton, Text } from '~components';
import { getDimensions, getTranslateParameters } from '~selectors';
import { navigationService } from '~services';

import * as audio from './audio';
import * as localize from './localize';
import logoutAction from './logoutAction';

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  sidebar: {
    backgroundColor: '$teal',
    justifyContent: 'center',
    flex: 1,
    flexBasis: 275,
    minWidth: 275,
  },
});

const Home = ({
  authActions,
  isComplete,
  isNew,
  isTablet,
  mediaActions,
  sessionActions,
  storedRoutes,
  translate,
}) => (
  <View style={styles.container}>
    {
      isTablet && (
        <View style={styles.sidebar}>
          <Button
            onPress={() => logoutAction({
              authActions,
              mediaActions,
              translate,
              sessionActions,
            })}
            title={translate('Home.logoutButton')}
            variant="homeSidebar"
          />
        </View>
      )
    }
    <FullScreen backgroundColor="mint">
      {
        isNew && (
          <View style={{ maxWidth: 500, alignSelf: 'center' }}>
            <Text style={{ color: 'black', lineHeight: '150%', textAlign: 'center' }}>
              {translate('Home.newBody')}
            </Text>
            <Button
              onPress={() => {
                mediaActions.releaseSound();
                mediaActions.unloadScreenNarration();
                sessionActions.setIsFooterNavEnabled(true);
                sessionActions.setIsNew(false);
                navigationService.navigate('ReadinessQuestion1');
              }}
              title={translate('Home.startButton')}
              variant="inlineLg"
              testID="Start-Button"
            />
          </View>
        )
      }
      {
        (!isComplete && !isNew) && (
          <View style={{ maxWidth: 500, alignSelf: 'center' }}>
            <Text style={{ color: 'black', lineHeight: '150%', textAlign: 'center', paddingBottom: 30 }}>
              {translate('Home.partialBody')}
            </Text>
            <Button
              onPress={() => {
                mediaActions.releaseSound();
                mediaActions.unloadScreenNarration();
                sessionActions.setIsFooterNavEnabled(true);
                navigationService.resume(storedRoutes);
              }}
              title={translate('Home.continueButton')}
              variant="inlineLg"
            />
          </View>
        )
      }
      {
        isComplete && (
          <View style={{ maxWidth: 500, alignSelf: 'center' }}>
            <Text style={{ color: 'black', lineHeight: '150%', textAlign: 'center', paddingBottom: 30 }}>
              {translate('Home.reportBody')}
            </Text>
            <ReportButton
              variant={['report']}
              title={translate('Home.reportButton')}
            />
          </View>
        )
      }
      {
        !isTablet && (
          <Button
            style={{ color: 'black', textAlign: 'center' }}
            onPress={() => logoutAction({
              authActions,
              mediaActions,
              translate,
              sessionActions,
            })}
            title={translate('Home.logoutButton')}
          />
        )
      }
    </FullScreen>
  </View>
);

Home.propTypes = {
  authActions: PropTypes.object.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  sessionActions: PropTypes.object.isRequired,
  storedRoutes: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
};

Home.navigationOptions = ({ screenProps: { translate } }) => ({
  title: translate && translate('Home.title'),
});

const mapStateToProps = state => ({
  isComplete: state.session.isComplete,
  isNew: state.session.routes.length === 0,
  isTablet: getDimensions(state).isTablet,
  storedRoutes: state.session.routes,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActionCreators, dispatch),
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(Home),
  {
    audio,
    localize,
    logoutAction,
  },
);
