export default {
  title: `
    Home
  `,
  newLead: `
    Welcome!
  `,
  newBody: `
    To get started, please click the <b>Begin</b>\u00a0button.
  `,
  partialBody: `
    To pick up where you left off, click the <b>Continue</b>\u00a0button.
  `,
  reportBody: `
    It looks like you have already used My Move. To see a
    Summary of your My Move information, click the <b>Summary</b>\u00a0button.
  `,
  startButton: `
    Begin
  `,
  continueButton: `
    Continue
  `,
  reportButton: `
    Your Summary
  `,
  seeInstructionsButton: `
    Instructions
  `,
  logoutButton: `
    Exit My Move
  `,
  drawerButton: `
    More Options »
  `,
  personalLogoutAlert: {
    title: `
      Exit My Move
    `,
    message: `
      Exiting will delete your information. Continue?
    `,
    negative: `
      Cancel
    `,
    positive: `
      Yes, delete my information
    `,
  },
};
