import * as types from '~actions/types';
import { storageApi } from '~api';

const initialState = {
  videoVariant: storageApi.DEFAULT_VIDEO_VARIANT,
};

export default function propertyReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PROPERTY: {
      return {
        ...state,
        ...action.property,
      };
    }

    case types.CLEAR_PROPERTY: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
