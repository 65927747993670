export default {
  yesBody: `
    Great! Next, you will learn more about how to get the coronavirus vaccine.
  `,
  unsureBody: `
    Okay. We have found that the next activity has helped people still
    considering coronavirus vaccination.
  `,
  noBody: `
    It looks like you are still not ready to get the vaccine. We hope My Move
    has helped you think about the coronavirus vaccine. We hope you discuss this
    more with your health care provider. On the next screen, you’ll have the
    chance to select your concerns.
  `,
  instruction: `
    Please click the <b>Continue</b> button.
  `,
};
