import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { sessionActionCreators } from '~actions';
import { FullScreen, RadioButtonGroup, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as localize from './localize';

const nextScreen = ({ session: { readinessQuestion3 } }) => (
  readinessQuestion3 ? 'ReadinessQuestion3Acknowledgment' : null
);
const sidePanelImage = 'Globe';

const ReadinessQuestion3 = ({ response, sessionActions, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, justifyContent: 'center', maxWidth: 900, alignSelf: 'center' }}>
      <Text variant="lead">
        {translate('ReadinessQuestion3.question')}
      </Text>
      <RadioButtonGroup
        selectedValues={[response]}
        onSelect={value => {
          sessionActions.setNextScreen('ReadinessQuestion3Acknowledgment');
          sessionActions.setReadinessQuestion3(value);
        }}
        options={[
          {
            value: '1',
            label: translate('ReadinessQuestion3.answer1'),
          },
          {
            value: '2',
            label: translate('ReadinessQuestion3.answer2'),
          },
          {
            value: '3',
            label: translate('ReadinessQuestion3.answer3'),
          },
        ]}
      />
    </View>
    <View style={{ height: 90, alignSelf: 'center' }}>
      <Text variant="instruction">
        {translate('ReadinessQuestion3.instruction')}
      </Text>
    </View>
  </FullScreen>
);

ReadinessQuestion3.propTypes = {
  response: PropTypes.string.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  response: state.session.readinessQuestion3,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
  dispatch,
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(ReadinessQuestion3),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
