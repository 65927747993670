export default {
  welcomeTitle: `
    ¡Bienvenido a My\u00a0Move!
  `,
  body: `
    Ingrese su clave de acceso para\u00a0comenzar.
  `,
  keyExplanation: `
    La clave de acceso no lo identifica. Solo identifica a la organización que
    lo remitió.
  `,
  submit: `
    Ingresar
  `,
  codeLabel: `
    Clave de acceso
  `,
  noKeyButton: `
    No tengo clave
  `,
  changeLanguageButton: `
    English
  `,
  errors: {
    network: `
      Lo sentimos, hubo un error de red. Por favor vuelva a intentar más tarde.
    `,
    code: `
      Lo sentimos, no identificamos la clave de acceso que ingresó. Por favor
      intente de nuevo.
    `,
    unknown: `
      Lo sentimos, hubo un error. Por favor vuelva a intentar más tarde.
    `,
  },
};
