import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import { colors } from '~styles';

export const CURVE_HEIGHT = 206;

const Curve = ({ heightOffset, fillColor }) => (
  <View
    style={{
      borderBottomColor: colors[fillColor],
      width: '100%',
      position: 'absolute',
      height: CURVE_HEIGHT - heightOffset,
    }}
    pointerEvents="none"
  >
    <svg
      width="107%"
      height={CURVE_HEIGHT}
      style={{
        marginLeft: '-7%',
      }}
    >
      <path
        d="M240.7 70.2s518.8-26.5 667.5-12c163.5 15.9 482.4 13.3 528.6 3.3C1594.5 27.4 1867 27.4 2005.4 83c5.8 2.3 0 39.9 0 39.9v81.3L20 210l2.2-168.4S125 75.9 240.7 70.2"
        fill={colors[fillColor]}
      />
    </svg>
  </View>
);

Curve.propTypes = {
  fillColor: PropTypes.string,
  heightOffset: PropTypes.number,
};

Curve.defaultProps = {
  fillColor: 'ink',
  heightOffset: 0,
};

export default Curve;
