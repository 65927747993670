import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, ResponsiveStacker, Text, WebButton } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const nextScreen = 'ActionPlanClosing';

const ActionPlanReferral = ({
  hasPhoneReferral,
  hasUrlReferral,
  isAppointmentScheduled,
  translate,
  url,
}) => (
  <FullScreen>
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 1000, alignSelf: 'center' }}>
      {
        isAppointmentScheduled ? (
          <ResponsiveStacker>
            <Text variant="lead">
              {translate('ActionPlanReferral.alreadyHasAppointment')}
            </Text>
            <View
              style={{
                flexBasis: 300,
                flexGrow: 0,
                maxWidth: '100%',
                aspectRatio: 1,
              }}
            >
              <Image
                source={images.DefaultSpotImage}
                style={{
                  flexBasis: 280,
                  maxWidth: '100%',
                }}
                resizeMode="contain"
              />
            </View>
          </ResponsiveStacker>
        ) : (
          <ResponsiveStacker>
            <View style={{ textAlign: 'center' }}>
              <Text variant="lead">
                {translate('ActionPlanReferral.no')}
              </Text>
              {hasPhoneReferral && (
                <>
                  <Text variant="lead">
                    {translate('ActionPlanReferral.phoneReferral')}
                  </Text>
                </>
              )}
              {hasUrlReferral && (
                <>
                  <Text variant="lead">
                    {translate('ActionPlanReferral.urlReferral')}
                    {' '}
                    <a href={url} target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                      {url}
                    </a>
                    .
                  </Text>
                </>
              )}
              {(!hasUrlReferral && !hasPhoneReferral) ? (
                <>
                  <Text style={{ marginTop: 30 }}>
                    {translate('ActionPlanReferral.noReferralProvider')}
                  </Text>
                  <View
                    style={{
                      borderTopColor: 'white',
                      borderTopWidth: 1,
                      width: '100%',
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  />
                  <Text style={{ paddingBottom: 10 }}>
                    {translate('ActionPlanReferral.noReferralClinic')}
                  </Text>
                  <WebButton
                    variant="inlineMd"
                    title={translate('ActionPlanReferral.healthDepartments')}
                    url="https://www.naccho.org/membership/lhd-directory"
                  />
                  <View
                    style={{
                      borderTopColor: 'white',
                      borderTopWidth: 1,
                      width: '100%',
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  />
                  <Text style={{ paddingBottom: 10 }}>
                    {translate('ActionPlanReferral.noReferralPharm')}
                  </Text>
                  <WebButton
                    variant="inlineMd"
                    title={translate('ActionPlanReferral.pharmacies')}
                    url="https://vaccinefinder.org/search/"
                  />
                </>
              ) : null}
            </View>
            <View
              style={{
                flexBasis: 200,
                flexGrow: 0,
                maxWidth: '100%',
                aspectRatio: 1,
              }}
            >
              <Image
                source={images.DefaultSpotImage}
                style={{
                  flexBasis: 200,
                  maxWidth: '100%',
                }}
                resizeMode="contain"
              />
            </View>
          </ResponsiveStacker>
        )
      }
    </View>
  </FullScreen>
);

ActionPlanReferral.propTypes = {
  isAppointmentScheduled: PropTypes.bool.isRequired,
  hasPhoneReferral: PropTypes.bool.isRequired,
  hasUrlReferral: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  hasPhoneReferral: state.property.vaccine_referral_phone[state.session.language] !== null,
  hasUrlReferral: state.property.vaccine_referral_link[state.session.language] !== null,
  isAppointmentScheduled: state.session.isAppointmentScheduled === 'Y',
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
  url: state.property.vaccine_referral_link[state.session.language],
});

export default Object.assign(
  connect(mapStateToProps)(ActionPlanReferral),
  {
    audio,
    localize,
    nextScreen,
  },
);
