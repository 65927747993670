export default {
  title: `
    Great job!
  `,
  body: `
    Maybe these questions helped you think of reasons to get the coronavirus
    vaccine.
  `,
  body2: `
    Please take a moment now to think of several reasons. On the next screen,
    you will speak your reasons.
  `,
};
