export default {
  body: `
    Thank you for taking the time to learn more about the coronavirus vaccine.
  `,
  learnMore: `
    To learn more about coronavirus and the vaccine, click the <b>Learn more</b> button.
  `,
  learnMoreButton: `
    Learn more
  `,
  summary: `
      If you would like to see a summary of what you covered in My Move, click the
      <b>Summary</b> button. You can share your summary with your healthcare provider
      if you like.
  `,
  done: `
    Click the <b>Done</b> button if you are finished.
  `,
  doneButton: `
    Done
  `,
  summaryButton: `
    Summary
  `,
};
