export default {
  tryAgainBody: `
    When you were referred to My Move, you should have been given an access key.
    Please check the original message again to find the key.
  `,
  tryAgainBody2: `
    If you got to My Move by clicking a link in an email message or on another
    website, try clicking that link again.
  `,
  watchVideoBody: `
    If you do not have an access key, you can still watch the My Move video.
  `,
  tryAgainButton: `
    Enter Access Key
  `,
  watchVideoButton: `
    Watch Video
  `,
};
