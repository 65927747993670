import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, ReportButton, ResponsiveStacker, Text, WebButton } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const sidePanelImage = 'BoyJumpingIntoPool';

const ConcernsClosing = ({ translate, url }) => (
  <FullScreen style={{ alignItems: 'center' }}>
    <Text variant="lead" style={{ textAlign: 'center', marginTop: 40 }}>
      {translate('ConcernsClosing.body')}
    </Text>
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 2 }}>
        <Text variant="small">
          {translate('ConcernsClosing.learnMore')}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <WebButton
          title={translate('ConcernsClosing.learnMoreButton')}
          url={url}
        />
      </View>
    </View>
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 1000, alignSelf: 'center' }}>
      <ResponsiveStacker>
        <View
          style={{
            flexBasis: 300,
            flexGrow: 0,
            maxWidth: '100%',
            aspectRatio: 1,
          }}
        >
          <Image
            source={images.DefaultSpotImage}
            style={{
              flexBasis: 280,
              maxWidth: '100%',
            }}
            resizeMode="contain"
          />
        </View>
        <View>
          <Text>
            {translate('ConcernsClosing.summary')}
          </Text>
          <ReportButton
            variant={['report', 'inlineMd']}
            title={translate('ConcernsClosing.summaryButton')}
          />
        </View>
      </ResponsiveStacker>
    </View>
  </FullScreen>
);

ConcernsClosing.propTypes = {
  translate: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
  url: state.property.general_referral_link[state.session.language],
});

export default Object.assign(
  connect(mapStateToProps)(ConcernsClosing),
  {
    audio,
    isClosing: true,
    localize,
    sidePanelImage,
  },
);
