export default {
  body: `
    Some people mention concerns about the coronavirus vaccine. What can
    doctors, pharmacists, or other healthcare workers say to lower their
    concerns?
  `,
  topic: `
    What doctors, pharmacists, or other healthcare workers can say to lower
    concerns about the coronavirus vaccine.
  `,
};
