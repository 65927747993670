const unlockAudioContext = context => {
  if (context.state === 'suspended') {
    const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
    const unlock = () => {
      events.forEach(e => document.body.removeEventListener(e, unlock));
      context.resume();
    };

    events.forEach(e => document.body.addEventListener(e, unlock, false));
  }
};

let audioContext;
let gainNode;
if (window.AudioContext || window.webkitAudioContext) {
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  gainNode = audioContext.createGain();
  gainNode.gain.value = 0.25;
  gainNode.connect(audioContext.destination);

  unlockAudioContext(audioContext);
}

export default class MediaApi {
  static async getScreenNarration(state) {
    const { media: { screenNarration } } = state;
    if (!screenNarration) {
      return null;
    }
    switch (typeof screenNarration) {
      case 'function':
        return screenNarration(state);
      case 'object': {
        if (!screenNarration.list) {
          return null;
        }
        const f = screenNarration.list[screenNarration.index];
        if (typeof f === 'function') {
          return f(state);
        }
        return null;
      }
      default:
        throw new Error('Bad screenNarration');
    }
  }

  static async loadSound({ action, getState }, allow, reject) {
    const screenNarration = await this.getScreenNarration(getState());
    if (screenNarration) {
      try {
        this.initSound(
          screenNarration,
          sound => allow({ ...action, sound }),
          () => reject(),
        );
      } catch (e) {
        reject();
      }
    }
  }

  static async initSound(url, oncanplaythrough, reject) {
    if (audioContext) {
      const request = new XMLHttpRequest();
      request.open(
        'GET',
        url,
        true,
      );
      request.responseType = 'arraybuffer';
      request.onload = () => audioContext.decodeAudioData(
        request.response,
        buffer => {
          const bufferSource = audioContext.createBufferSource();
          bufferSource.buffer = buffer;
          bufferSource.connect(gainNode);
          oncanplaythrough(bufferSource);
        },
      );
      request.send();
    } else {
      reject();
    }
  }

  static playSound({ getState }) {
    if (audioContext) {
      const { media: { isAudioEnabled, onEnd, sound } } = getState();

      // check if context is in suspended state (autoplay policy)
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }

      sound.onended = onEnd;
      try {
        if (sound) {
          isAudioEnabled && sound.start();
        }
      } catch (e) {
        // not sure
      }
    }
  }

  static pauseSound({ getState }) {
    const { media: { sound } } = getState();
    if (sound) {
      sound.pause();
    }
  }

  static stopSound({ getState }) {
    const { media: { sound } } = getState();
    if (sound) {
      sound.disconnect();
    }
  }

  static releaseSound({ getState }) {
    const { media: { sound } } = getState();
    if (sound) {
      sound.disconnect();
    }
  }
}
