import React from 'react';
import PropTypes from 'prop-types';
import StackNavigator from '~navigators/StackNavigator';

import * as screens from './screens';

const Navigator = StackNavigator({
  initialRouteName: 'Home',
  screens,
});

const IsNotViewingContent = ({ navigation }) => (
  <Navigator
    navigation={navigation}
  />
);

IsNotViewingContent.router = {
  ...Navigator.router,
};

IsNotViewingContent.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default IsNotViewingContent;
