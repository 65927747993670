import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { FullScreen, ResponsiveStacker, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as images from './img';
import * as localize from './localize';

const nextScreen = 'Reasons';
const sidePanelImage = 'GirlOnBike';

const ReasonsIntro = ({ translate }) => (
  <FullScreen backgroundColor="rust">
    <View style={{ flex: 1, flexDirection: 'row', maxWidth: 900, alignSelf: 'center' }}>
      <ResponsiveStacker>
        <View
          style={{
            flexBasis: 300,
            flexGrow: 0,
            maxWidth: '100%',
            aspectRatio: 1,
          }}
        >
          <Image
            source={images.DefaultSpotImage}
            style={{
              flexBasis: 280,
              maxWidth: '100%',
            }}
            resizeMode="contain"
          />
        </View>
        <Text variant="lead">
          {translate('ReasonsIntro.body')}
        </Text>
      </ResponsiveStacker>
    </View>
  </FullScreen>
);

ReasonsIntro.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(ReasonsIntro),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
