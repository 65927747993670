import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { Text } from '~components';
import RecordingScreen from '~components/RecordingScreen';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';

const nextScreen = 'Question3';
const recordingScreenName = 'Question2';

const Question2 = ({
  selectedQuestion,
  translate,
}) => (
  <RecordingScreen screenName={recordingScreenName}>
    <Text variant="lead">
      {translate(`Questions.${selectedQuestion}.body`)}
    </Text>
  </RecordingScreen>
);

Question2.propTypes = {
  selectedQuestion: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedQuestion: state.session.selectedQuestions[1],
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(Question2),
  {
    audio: { ...audio, narration: { index: 1, ...audio.narration } },
    nextScreen,
    recordingScreenName,
  },
);
