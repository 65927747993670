export default {
  subject: `
    Resumen de My Move
  `,
  lead: `
    ¡Gracias por usar My Move!
  `,
  body: `
    Aquí tiene el resumen que solicitó. Aparece dentro del presente mensaje y
    también en el archivo PDF adjunto.
  `,
  signature: `
    - El equipo de My Move
  `,
};
