import * as types from '~actions/types';

const initialState = {
  activeImageIndex: 0,
  activeImages: [undefined, undefined],
  activeRecordingScreenName: null,
  age: '',
  createdAt: null,
  error: null,
  gender: 'n',
  isActive: false,
  isAppointmentScheduled: '',
  isComplete: false,
  isFooterBackEnabled: false,
  isFooterHomeEnabled: false,
  isFooterNavEnabled: false,
  isNew: true,
  language: 'en',
  name: '',
  nextScreen: '',
  passcode: '',
  readinessQuestion1: '',
  readinessQuestion2: '',
  readinessQuestion3: '',
  recordings: {},
  routes: [],
  selectedConcerns: [],
  selectedQuestions: [],
  timerId: null,
  timerValue: 0,
  uuid: null,
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_SESSION_SUCCESS: {
      const { session } = action.payload;
      return {
        language: session.language,
        ...initialState,
        ...session,
        isActive: true,
      };
    }

    case types.CREATE_SESSION_ERROR: {
      return {
        ...state,
        error: action.error.message,
      };
    }

    case types.RETRIEVE_SESSION_SUCCESS: {
      const { session } = action;
      return session;
    }

    case types.RETRIEVE_SESSION_ERROR: {
      return {
        ...state,
        error: action.error.message,
      };
    }

    case types.STORE_RECORDING_FILENAME: {
      const { recordings } = state;
      const { filename, screenName } = action.payload;
      return {
        ...state,
        recordings: {
          ...recordings,
          [screenName]: filename,
        },
      };
    }

    case types.SET_LANGUAGE: {
      return {
        ...state,
        language: action.value,
      };
    }

    case types.SET_PARAM: {
      return {
        ...state,
        [action.param]: action.value,
      };
    }

    case types.RESET_TIMER: {
      return {
        ...state,
        timerValue: 0,
      };
    }

    case types.INCREMENT_TIMER: {
      return {
        ...state,
        timerValue: state.timerValue + 1,
      };
    }

    case types.RESET_APP:
    case types.END_SESSION:
    case types.DELETE_SESSION_SUCCESS: {
      const persistentState = {
        language: state.language,
      };
      return {
        ...initialState,
        ...persistentState,
      };
    }

    case types.ADD_SELECTION: {
      return {
        ...state,
        [action.param]: [...state[action.param], action.value],
      };
    }

    case types.REMOVE_SELECTION: {
      return {
        ...state,
        [action.param]: state[action.param].filter(v => v !== action.value),
      };
    }

    case types.SET_SIDE_PANEL_IMAGE: {
      const { image } = action;
      const { activeImageIndex, activeImages } = state;
      if (activeImages[activeImageIndex] === image) {
        return state;
      }
      const newIndex = (
        activeImages[activeImageIndex] === undefined ? activeImageIndex : 1 - activeImageIndex
      );
      activeImages[newIndex] = image;
      return {
        ...state,
        activeImages,
        activeImageIndex: newIndex,
      };
    }

    default:
      return state;
  }
}
