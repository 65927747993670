export default {
  body: `
    How can getting the vaccine in addition to wearing a mask and maintaining
    social distance help you feel more secure?
  `,
  topic: `
    How getting the vaccine (in addition to wearing a mask and maintaining
    social distance) can help me feel more secure.
  `,
  bodyAlt: `
    How can getting the vaccine help you feel more secure?
  `,
  topicAlt: `
    How getting the vaccine can help me feel more secure.
  `,
};
