import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { sessionActionCreators } from '~actions';
import { FullScreen, RadioButtonGroup, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as audio from './audio';
import * as localize from './localize';

const nextScreen = ({ session: { selectedQuestions } }) => (
  selectedQuestions.length === 3 ? 'QuestionsIntro' : null
);
const sidePanelImage = 'FlattopDudeArmsCrossedConcerned';

const QuestionsSelection = ({ selectedValues, sessionActions, translate }) => (
  <FullScreen>
    <View style={{ flex: 1, justifyContent: 'center', maxWidth: 900, alignSelf: 'center' }}>
      <Text variant="lead">
        {translate('QuestionsSelection.body')}
      </Text>
      <RadioButtonGroup
        selectedValues={selectedValues}
        onSelect={value => {
          if (selectedValues.includes(value)) {
            if (selectedValues.length === 4) {
              sessionActions.setNextScreen('QuestionsIntro');
            } else {
              sessionActions.setNextScreen(null);
            }
          } else if (selectedValues.length >= 2) {
            sessionActions.setNextScreen('QuestionsIntro');
          } else {
            sessionActions.setNextScreen(null);
          }
          sessionActions.toggleSelectedQuestion(value);
        }}
        options={['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].map(topic => ({
          value: topic,
          label: translate(`QuestionsSelection.topics.${topic}`),
        }))}
      />
    </View>
    <View style={{ height: 70, alignSelf: 'center' }}>
      <Text variant="instruction">
        {translate('QuestionsSelection.instruction')}
      </Text>
    </View>
  </FullScreen>
);

QuestionsSelection.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedValues: state.session.selectedQuestions,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
  dispatch,
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(QuestionsSelection),
  {
    audio,
    localize,
    nextScreen,
    sidePanelImage,
  },
);
