import A from './A';
import B from './B';
import C from './C';
import D from './D';
import E from './E';
import F from './F';
import G from './G';
import H from './H';

export default {
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
};
