import * as types from '~actions/types';

const initialState = {
  orientation: 'portrait',
  height: 0,
  width: 0,
  isTablet: true,
  versionString: '',
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ORIENTATION: {
      return {
        ...state,
        orientation: action.orientation,
      };
    }

    case types.SET_DIMENSIONS: {
      return {
        ...state,
        height: action.height,
        width: action.width,
      };
    }

    case types.SET_IS_TABLET: {
      return {
        ...state,
        isTablet: action.isTablet,
      };
    }

    case types.SET_VERSION_STRING: {
      return {
        ...state,
        versionString: action.versionString,
      };
    }

    default: {
      return state;
    }
  }
}
