import * as types from '~actions/types';

const initialState = {
  isAudioEnabled: true,
  onEnd: null,
  screenNarration: null,
  sound: null,
};

export default function mediaReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_SCREEN_NARRATION: {
      const { screenNarration } = action;
      return {
        ...state,
        screenNarration,
      };
    }

    case types.LOAD_SOUND_SUCCESS: {
      const { onEnd, sound } = action.payload;
      return {
        ...state,
        onEnd,
        sound,
      };
    }

    case types.PLAY_SOUND_SUCCESS: {
      return {
        ...state,
        isPaused: false,
      };
    }

    case types.PAUSE_SOUND_SUCCESS: {
      return {
        ...state,
        isPaused: true,
      };
    }

    case types.RELEASE_SOUND_SUCCESS: {
      return {
        ...state,
        sound: null,
      };
    }

    case types.UNLOAD_SCREEN_NARRATION: {
      return {
        ...state,
        screenNarration: null,
      };
    }

    case types.STOP_AUDIO: {
      return {
        ...state,
        isAudioEnabled: false,
      };
    }

    case types.PLAY_AUDIO: {
      return {
        ...state,
        isAudioEnabled: true,
      };
    }

    default:
      return state;
  }
}
