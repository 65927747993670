import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { mediaActionCreators, sessionActionCreators } from '~actions';
import { navigationService } from '~services';
import Button from '~components/Button';

import * as localize from './localize';

const BackButton = ({ isFooterBackEnabled, mediaActions, sessionActions, translate }) => (
  <Button
    onPress={() => {
      if (isFooterBackEnabled) {
        mediaActions.releaseSound();
        mediaActions.unloadScreenNarration();
        sessionActions.setActiveRecordingScreenName(null);
        navigationService.back();
      } else {
        mediaActions.releaseSound();
        mediaActions.unloadScreenNarration();
        sessionActions.setIsFooterNavEnabled(false);
        navigationService.navigate('Home');
      }
    }}
    variant="nav"
    title={translate('BackButton.back')}
  />
);

BackButton.propTypes = {
  isFooterBackEnabled: PropTypes.bool.isRequired,
  mediaActions: PropTypes.object.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFooterBackEnabled: state.session.isFooterBackEnabled,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(BackButton),
  {
    localize,
  },
);
