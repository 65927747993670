export default {
  body: `
    A continuación, se le harán preguntas basadas en los tres temas que haya
    seleccionado. Después de cada pregunta, podrá grabar su voz mientras responde.
  `,
  // TODO second sentence, see en
  unavailable: `
    A continuación, se le harán preguntas basadas en los tres temas que haya
    seleccionado.
  `,
  microphone: `
    My Move necesita su permiso para acceder a su micrófono cuando opta por
    grabar su voz. Sus grabaciones NO saldrán de su computadora y no serán
    compartidas con My Move, su proveedor de servicios de salud o cualquier
    otra persona.
  `,
  microphoneButton: `
    Permiso para acceder al micrófono
  `,
  denied: `
    My Move no puede acceder a su micrófono. Es posible que la configuración
    de su navegador lo esté bloqueando. Puede seguir utilizando My Move sin
    grabar su voz.
  `,
  authorized: `
    Pulse el botón <b>Continuar</b>.
  `,
};
