export default {
  body: `
    ¿Cómo puede ayudarle a sentirse más seguro el hecho de vacunarse, además
    de usar una mascarilla y mantener la distancia social?
  `,
  topic: `
    Cómo el hecho de vacunarme (además de usar una mascarilla y mantener la
      distancia social) puede ayudarme a sentirme más seguro.
  `,
  bodyAlt: `
    ¿Cómo puede ayudarle a sentirse más seguro el hecho de haberse vacunado?
  `,
  topicAlt: `
    Cómo puede el hecho de haberme vacunado ayudarme a sentirme más seguro.
  `,
};
