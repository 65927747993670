import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { mediaActionCreators } from '~actions';
import Button from '~components/Button';

const WebButton = ({
  mediaActions,
  title,
  url,
  variant,
}) => (
  <Button
    onPress={() => {
      mediaActions.releaseSound();
      window.open(
        url,
        '_blank',
      );
    }}
    title={title}
    variant={variant}
  />
);

WebButton.propTypes = {
  mediaActions: PropTypes.object.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

WebButton.defaultProps = {
  title: null,
  variant: 'web',
};

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(WebButton);
