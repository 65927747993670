import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import StackNavigator from '~navigators/StackNavigator';
import { getTranslateParameters } from '~selectors';

import * as screens from './screens';

const Navigator = StackNavigator({
  initialRouteName: 'Landing',
  screens,
});

const IsAuthenticated = ({ navigation, translate }) => (
  <Navigator
    navigation={navigation}
    screenProps={{ translate }}
  />
);

IsAuthenticated.propTypes = {
  navigation: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

IsAuthenticated.router = Navigator.router;

const mapStateToProps = state => ({
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default connect(mapStateToProps)(IsAuthenticated);
