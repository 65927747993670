export default {
  body: `
    How can people getting the vaccine help your community get back to work and
    school?
  `,
  topic: `
    How people getting the vaccine can help my community get back to work and
    school.
  `,
};
