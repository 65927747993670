import localForage from 'localforage';

const RECORDING_KEYCHAIN = 'recordings';

const getKeychain = async keychainName => {
  const keychain = await localForage.getItem(keychainName);
  if (keychain) {
    return keychain;
  }
  throw new Error(`Keychain ${keychainName} not found.`);
};

export default class StorageApi {
  static FIRST_LAUNCHED_AT = 'firstLaunchedAt';

  static TOKEN = 'token';

  static PROPERTY = 'property';

  static FAILED_ATTEMPTS = 'failedAttempts';

  static SESSIONS_KEYCHAIN = 'sessionsKeychain';

  static AUTH_KEYCHAIN = 'authKeychain';

  static VIDEO_KEYCHAIN = 'videoKeychain';

  static DEFAULT_VIDEO_VARIANT = 'default';

  static async deleteAll() {
    await this.delete(this.FIRST_LAUNCHED_AT);
    await this.delete(this.TOKEN);
    await this.delete(this.PROPERTY);
    await this.delete(this.FAILED_ATTEMPTS);
    await this.deleteAllSessions();
    await this.deleteAllVideos();
  }

  static async retrieveFailedAttempts() {
    let failedAttempts = 0;
    try {
      failedAttempts = parseInt(await this.retrieve(this.FAILED_ATTEMPTS), 10);
    } catch (e) {
      await this.clearFailedAttempts();
    }
    return failedAttempts;
  }

  static async incrementFailedAttempts() {
    const failedAttempts = await this.retrieveFailedAttempts();
    return this.store(this.FAILED_ATTEMPTS, failedAttempts + 1);
  }

  static async clearFailedAttempts() {
    return this.store(this.FAILED_ATTEMPTS, 0);
  }

  static async storeProperty(property) {
    return this.store(this.PROPERTY, property);
  }

  static async retrieveProperty() {
    return this.retrieve(this.PROPERTY);
  }

  static async storeToken(token) {
    return this.store(this.TOKEN, token);
  }

  static async retrieveToken() {
    return this.retrieve(this.TOKEN);
  }

  static async storeSession(session) {
    return this.store(session.uuid, session, this.SESSIONS_KEYCHAIN);
  }

  static async retrieveStoredSessions() {
    return this.getAllItems(this.SESSIONS_KEYCHAIN);
  }

  static async retrieveStoredSessionByPasscode(passcode) {
    const storedSessions = await this.retrieveStoredSessions();
    const storedSession = storedSessions.find(
      session => session.passcode === passcode,
    );
    if (!storedSession) {
      throw new Error('bad_passcode');
    }
    return storedSession;
  }

  static async retrieveSoloSession() {
    try {
      const storedSessions = await this.retrieveStoredSessions();
      if (storedSessions.length === 0) {
        throw new Error('no_stored_sessions');
      }
      return storedSessions[0];
    } catch (e) {
      throw new Error('no_stored_sessions');
    }
  }

  static async checkForDefaultVideos() {
    try {
      return !!(await this.retrieve(this.DEFAULT_VIDEO_VARIANT, this.VIDEO_KEYCHAIN));
    } catch (e) {
      return false;
    }
  }

  static async retrieveVideoVariant(variant = this.DEFAULT_VIDEO_VARIANT) {
    return this.retrieve(variant, this.VIDEO_KEYCHAIN);
  }

  static async addVideoToVariant(lang, path, variant = this.DEFAULT_VIDEO_VARIANT) {
    let oldVariant;
    try {
      oldVariant = await this.retrieveVideoVariant(variant);
    } catch (e) {
      oldVariant = {};
    }
    await this.store(
      variant,
      {
        ...oldVariant,
        [lang]: path,
        variant,
      },
      this.VIDEO_KEYCHAIN,
    );
  }

  static async checkHasExpired() {
    try {
      const property = await this.retrieveProperty();
      return (property && (Date.now() - property.createdAt > 3600000));
    } catch (e) {
      return false;
    }
  }

  static async store(key, value, keychainName = this.AUTH_KEYCHAIN) {
    try {
      const keychain = await getKeychain(keychainName);
      delete keychain[key];
      return localForage.setItem(
        keychainName,
        {
          ...keychain,
          [key]: value,
        },
      );
    } catch (e) {
      return localForage.setItem(keychainName, { [key]: value });
    }
  }

  static async retrieve(key, keychainName = this.AUTH_KEYCHAIN) {
    const keychain = await getKeychain(keychainName);
    if (keychain[key]) {
      return keychain[key];
    }
    throw new Error(`Key ${key} not found in ${keychainName}.`);
  }

  static async delete(key, keychainName = this.AUTH_KEYCHAIN) {
    try {
      const keychain = await getKeychain(keychainName);
      delete keychain[key];
      return localForage.setItem(
        keychainName,
        keychain,
      );
    } catch (e) {
      return localForage.setItem(keychainName, {});
    }
  }

  static async deleteSession(session) {
    this.delete(session.uuid, this.SESSIONS_KEYCHAIN);
  }

  // this returns an array of all the values in keychainName, not keys
  static async getAllItems(keychainName = this.AUTH_KEYCHAIN) {
    return Object.values(await getKeychain(keychainName));
  }

  static async deleteAllSessions() {
    await localForage.setItem(this.SESSIONS_KEYCHAIN, {});
    return localForage.setItem(RECORDING_KEYCHAIN, {});
  }

  static async deleteAllVideos() {
    return localForage.setItem(this.VIDEO_KEYCHAIN, {});
  }

  static async storeRecordingWithFilename(recording, filename) {
    return this.store(filename, recording, RECORDING_KEYCHAIN);
  }

  static async retrieveRecording(filename) {
    return this.retrieve(filename, RECORDING_KEYCHAIN);
  }
}
