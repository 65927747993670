export default {
  title: `
    Inicio
  `,
  newLead: `
    ¡Bienvenido!
  `,
  newBody: `
    Pulse el botón <b>Comenzar</b> para\u00a0comenzar.
  `,
  partialBody: `
    Si ya comenzó con el proceso anteriormente, pulse el botón <b>Continuar</b>
    para\u00a0reanudarlo.
  `,
  reportBody: `
    Parece que ya repasó el programa entero de My Move. Para ver un
    resumen de sus datos en My Move, pulse el\u00a0botón\u00a0<b>Resumen</b>.
  `,
  startButton: `
    Comenzar
  `,
  continueButton: `
    Continuar
  `,
  reportButton: `
    Su resumen
  `,
  seeInstructionsButton: `
    Instrucciones
  `,
  logoutButton: `
    Salir de My Move
  `,
  drawerButton: `
    Mas opciones »
  `,
  personalLogoutAlert: {
    title: `
      Salir de My Move
    `,
    message: `
      Salir eliminará sus datos. ¿Continuar?
    `,
    negative: `
      Cancelar
    `,
    positive: `
      Si, borre mis datos
    `,
  },
};
