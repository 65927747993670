import { createMiddleware } from 'redux-beacon';
import GoogleAnalyticsGtag, { trackEvent, trackPageView } from '@redux-beacon/google-analytics-gtag';

import * as types from '~actions/types';
import { sessionApi } from '~api';

const eventsMapper = ({ type, ...args }) => {
  // screen views are tracked separately from events
  if (type === types.VIEW_SCREEN) {
    return trackPageView(() => ({
      title: args.screenName,
    }));
  }

  // always ignore these actions
  if ([
    types.INCREMENT_TIMER,
    types.SET_SIDE_PANEL_IMAGE,
    types.CREATE_SESSION_SUCCESS,
    types.LOAD_SOUND_SUCCESS,
    types.LOAD_SOUND,
    types.STOP_SOUND,
    types.LOAD_SCREEN_NARRATION,
    types.UNLOAD_SCREEN_NARRATION,
    types.RELEASE_SOUND,
    types.SET_TIMER,
    types.TOGGLE_SELECTION,
    types.START_RECORDING_SUCCESS,
    types.STORE_RECORDING_FILENAME,
    types.STOP_RECORDING,
    types.START_RECORDING_PLAYBACK_SUCCESS,
    types.STOP_RECORDING_PLAYBACK,
    types.STOP_RECORDING_PLAYBACK_SUCCESS,
  ].includes(type)) {
    return undefined;
  }

  // SET_PARAM is overloaded, so handle it separately
  if (type === types.SET_PARAM) {
    // always ignore these params
    if (['activeImageIndex',
      'activeImages',
      'activeRecordingScreenName',
      'isActive',
      'isComplete',
      'isFooterBackEnabled',
      'isFooterHomeEnabled',
      'isFooterNavEnabled',
      'isNew',
      'nextScreen',
      'passcode',
      'routes',
    ].includes(args.param)) {
      return undefined;
    }

    if (args.param === 'name') {
      // eslint-disable-next-line no-param-reassign
      args.value = '';
    }
  }

  const [category, action] = type.split('/');

  switch (category) {
    case '@@localize':
    case 'Boot':
    case 'Device':
    case 'Property':
    case 'Auth':
      return undefined;
    default:
      break;
  }

  switch (action) {
    case 'CHECK_FOR_SOLO_SESSION':
      return undefined;
    default:
      break;
  }

  const label = Object.keys(args).length ? JSON.stringify(args) : undefined;

  return trackEvent(() => ({
    category,
    action,
    label,
  }));
};

const logger = events => (
  events.filter(({ action, event_category, page_title }) => {
    switch (page_title) {
      case 'Landing':
      case 'LandingNoKey':
      case 'LandingVideo':
        return false;
      default:
        break;
    }

    switch (event_category) {
      case '@@localize':
      case 'Boot':
      case 'Device':
      case 'Property':
      case 'Auth':
        return false;
      default:
        break;
    }

    switch (action) {
      case 'CHECK_FOR_SOLO_SESSION':
        return false;
      default:
        return true;
    }
  }).forEach(event => sessionApi.postEvent(event))
);

export default createMiddleware(
  eventsMapper,
  GoogleAnalyticsGtag('G-RGSKKDDXML'),
  {
    logger,
  },
);
