export const BOOT = 'Boot/BOOT';

export const LOAD_SCREEN_NARRATION = 'Media/LOAD_SCREEN_NARRATION';
export const UNLOAD_SCREEN_NARRATION = 'Media/UNLOAD_SCREEN_NARRATION';
export const LOAD_SOUND = 'Media/LOAD_SOUND';
export const LOAD_SOUND_SUCCESS = 'Media/LOAD_SOUND_SUCCESS';
export const LOAD_SOUND_ERROR = 'Media/LOAD_SOUND_ERROR';
export const PLAY_SOUND = 'Media/PLAY_SOUND';
export const PLAY_SOUND_SUCCESS = 'Media/PLAY_SOUND_SUCCESS';
export const PLAY_SOUND_ERROR = 'Media/PLAY_SOUND_ERROR';
export const PAUSE_SOUND = 'Media/PAUSE_SOUND';
export const PAUSE_SOUND_SUCCESS = 'Media/PAUSE_SOUND_SUCCESS';
export const PAUSE_SOUND_ERROR = 'Media/PAUSE_SOUND_ERROR';
export const STOP_SOUND = 'Media/STOP_SOUND';
export const STOP_SOUND_SUCCESS = 'Media/STOP_SOUND_SUCCESS';
export const STOP_SOUND_ERROR = 'Media/STOP_SOUND_ERROR';
export const RELEASE_SOUND = 'Media/RELEASE_SOUND';
export const RELEASE_SOUND_SUCCESS = 'Media/RELEASE_SOUND_SUCCESS';
export const RELEASE_SOUND_ERROR = 'Media/RELEASE_SOUND_ERROR';
export const STOP_AUDIO = 'Media/STOP_AUDIO';
export const PLAY_AUDIO = 'Media/PLAY_AUDIO';

export const FETCH_AUTH = 'Auth/FETCH_AUTH';
export const FETCH_AUTH_SUCCESS = 'Auth/FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_ERROR = 'Auth/FETCH_AUTH_ERROR';
export const RESET_APP = 'Auth/RESET_APP';

export const CHECK_FOR_SOLO_SESSION = 'Session/CHECK_FOR_SOLO_SESSION';
export const CHECK_FOR_SOLO_SESSION_SUCCESS = 'Session/CHECK_FOR_SOLO_SESSION_SUCCESS';
export const CHECK_FOR_SOLO_SESSION_ERROR = 'Session/CHECK_FOR_SOLO_SESSION_ERROR';
export const CREATE_SESSION = 'Session/CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'Session/CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_ERROR = 'Session/CREATE_SESSION_ERROR';
export const RETRIEVE_SESSION = 'Session/RETRIEVE_SESSION';
export const RETRIEVE_SESSION_SUCCESS = 'Session/RETRIEVE_SESSION_SUCCESS';
export const RETRIEVE_SESSION_ERROR = 'Session/RETRIEVE_SESSION_ERROR';
export const STORE_SESSION = 'Session/STORE_SESSION';
export const DELETE_SESSION = 'Session/DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'Session/DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_ERROR = 'Session/DELETE_SESSION_ERROR';
export const STORE_RECORDING_FILENAME = 'Session/STORE_RECORDING_FILENAME';
export const SET_LANGUAGE = 'Session/SET_LANGUAGE';
export const SET_PARAM = 'Session/SET_PARAM';
export const SET_TIMER = 'Session/SET_TIMER';
export const CLEAR_TIMER = 'Session/CLEAR_TIMER';
export const RESET_TIMER = 'Session/RESET_TIMER';
export const INCREMENT_TIMER = 'Session/INCREMENT_TIMER';
export const END_SESSION = 'Session/END_SESSION';
export const TOGGLE_SELECTION = 'Session/TOGGLE_SELECTION';
export const ADD_SELECTION = 'Session/ADD_SELECTION';
export const REMOVE_SELECTION = 'Session/REMOVE_SELECTION';
export const SET_SIDE_PANEL_IMAGE = 'Session/SET_SIDE_PANEL_IMAGE';

export const START_RECORDING = 'Recording/START_RECORDING';
export const START_RECORDING_SUCCESS = 'Recording/START_RECORDING_SUCCESS';
export const START_RECORDING_ERROR = 'Recording/START_RECORDING_ERROR';
export const STOP_RECORDING = 'Recording/STOP_RECORDING';
export const STOP_RECORDING_SUCCESS = 'Recording/STOP_RECORDING_SUCCESS';
export const STOP_RECORDING_ERROR = 'Recording/STOP_RECORDING_ERROR';
export const START_RECORDING_PLAYBACK = 'Recording/START_RECORDING_PLAYBACK';
export const START_RECORDING_PLAYBACK_SUCCESS = 'Recording/START_RECORDING_PLAYBACK_SUCCESS';
export const START_RECORDING_PLAYBACK_ERROR = 'Recording/START_RECORDING_PLAYBACK_ERROR';
export const STOP_RECORDING_PLAYBACK = 'Recording/STOP_RECORDING_PLAYBACK';
export const STOP_RECORDING_PLAYBACK_SUCCESS = 'Recording/STOP_RECORDING_PLAYBACK_SUCCESS';
export const STOP_RECORDING_PLAYBACK_ERROR = 'Recording/STOP_RECORDING_PLAYBACK_ERROR';

export const VIEW_SCREEN = 'App/VIEW_SCREEN';

export const SET_ORIENTATION = 'Device/SET_ORIENTATION';
export const SET_DIMENSIONS = 'Device/SET_DIMENSIONS';
export const SET_IS_TABLET = 'Device/SET_IS_TABLET';
export const SET_VERSION_STRING = 'Device/SET_VERSION_STRING';

export const SET_PROPERTY = 'Property/SET_PROPERTY';
export const CLEAR_PROPERTY = 'Property/CLEAR_PROPERTY';
