export default {
  body: `
    How can your getting the vaccine help others in your community to stay
    healthy or get the vaccine themselves?
  `,
  topic: `
    How getting the vaccine can help others in my community to stay healthy or
    get the vaccine themselves.
  `,
};
