export default {
  subject: `
    My Move Summary
  `,
  lead: `
    Thank you for using My Move!
  `,
  body: `
    Here is the Summary you requested. It is included in the body of this
    message and as a PDF attachment.
  `,
  signature: `
    - The My Move Team
  `,
};
