import React from 'react';
import {
  createNavigator,
  getActiveChildNavigationOptions,
  SceneView,
  StackRouter,
} from '@react-navigation/core';

const myCreateNavigator = ({ initialRouteName, screens }) => createNavigator(
  ({ descriptors, navigation }) => {
    const activeKey = navigation.state.routes[navigation.state.index].key;
    const descriptor = descriptors[activeKey];
    return (
      <SceneView
        navigation={descriptor.navigation}
        component={descriptor.getComponent()}
      />
    );
  },
  StackRouter(screens, { initialRouteName }),
  {
    navigationOptions: ({ navigation, screenProps }) => {
      const options = getActiveChildNavigationOptions(navigation, screenProps);
      return { title: options.title };
    },
  },
);

export default options => {
  const navigator = myCreateNavigator(options);

  const { router } = navigator;

  navigator.router = {
    ...router,
  };

  return navigator;
};
