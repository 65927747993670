import { createSwitchNavigator } from 'react-navigation';
import { BACK, INIT, NAVIGATE } from '@react-navigation/core/src/NavigationActions';
import { COMPLETE_TRANSITION, RESET } from '@react-navigation/core/src/routers/StackActions';
import { bindActionCreators } from 'redux';

import { appActionCreators, mediaActionCreators, sessionActionCreators, recorderActionCreators } from '~actions';
import store from '~store';

import screens from './screens';

const [appActions, mediaActions, sessionActions, recorderActions] = [
  appActionCreators, mediaActionCreators, sessionActionCreators, recorderActionCreators,
].map(actionCreators => bindActionCreators(actionCreators, store.dispatch));

const navigator = createSwitchNavigator(screens, {
  cardStyle: {
    shadowOpacity: 0,
  },
  headerMode: 'none',
  initialRouteName: 'Loading',
});

const { router } = navigator;

let previousToChildKey;

const getActiveRoute = route => {
  if (!route.routes || route.routes.length === 0 || route.index >= route.routes.length) {
    return route;
  }
  return getActiveRoute(route.routes[route.index]);
};

navigator.router = {
  ...router,
  getStateForAction: (action, state) => {
    const newState = router.getStateForAction(action, state);
    if (
      action.type === INIT
      || (action.type === COMPLETE_TRANSITION && action.toChildKey === previousToChildKey)
    ) {
      return newState;
    }

    const getNextScreen = nextScreen => {
      switch (typeof nextScreen) {
        case 'function':
          return nextScreen(store.getState());
        case 'string':
          return nextScreen;
        default:
          return null;
      }
    };

    const handleMedia = _state => {
      const {
        audio: { narration } = { narration: null },
      } = router.getComponentForState(_state);
      if (narration) {
        mediaActions.loadScreenNarration(narration);
        mediaActions.loadSound();
      }
    };

    switch (action.type) {
      case RESET:
      case BACK:
      case NAVIGATE: {
        const previousComponent = router.getComponentForState(state);
        const {
          isClosing,
          nextScreen,
          recordingScreenName,
          sidePanelImage,
        } = router.getComponentForState(newState);

        appActions.viewScreen(getActiveRoute(newState).routeName);

        sessionActions.setNextScreen(getNextScreen(nextScreen));
        if ([
          'isNotAuthenticated',
          'Landing',
          'isAuthenticated',
          'Summary',
          'isInSession',
          'Home',
        ].includes(action.routeName)) {
          if (typeof document === 'undefined') {
            handleMedia(newState);
          }
        }
        if (sidePanelImage) {
          sessionActions.setSidePanelImage(sidePanelImage);
        }
        if (previousComponent.recordingScreenName) {
          recorderActions.stopRecording();
          recorderActions.stopRecordingPlayback();
        }
        sessionActions.setActiveRecordingScreenName(recordingScreenName);

        if (isClosing) {
          sessionActions.setIsComplete(true);
        }

        break;
      }
      case COMPLETE_TRANSITION: {
        const { recordingScreenName } = router.getComponentForState(newState);
        sessionActions.setActiveRecordingScreenName(recordingScreenName);

        previousToChildKey = action.toChildKey;
        handleMedia(newState);
        break;
      }
      default:
        break;
    }
    return newState;
  },
};

export default navigator;
