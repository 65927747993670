import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { View } from 'react-native';

import { FullScreen, Video as VideoComponent, Text } from '~components';
import { getTranslateParameters } from '~selectors';

import * as localize from './localize';

const nextScreen = 'AnsweringInstructions';

const RecordingInstructions = ({
  language,
  navigation: { isFocused },
  translate,
}) => (
  <FullScreen style={{ paddingHorizontal: 25 }}>
    <View style={{ width: '100%', maxWidth: 1000, alignSelf: 'center' }}>
      <Text>
        {translate('RecordingInstructions.instructions')}
      </Text>
      {isFocused() && (
        <VideoComponent
          url={{
            en: 'https://player.vimeo.com/video/524334129',
            es: 'https://player.vimeo.com/video/528421107',
          }[language]}
          language={language}
        />
      )}
      <View style={{ height: 90, alignSelf: 'center' }}>
        <Text variant="instruction">
          {translate('RecordingInstructions.done')}
        </Text>
      </View>
    </View>
  </FullScreen>
);

RecordingInstructions.propTypes = {
  language: PropTypes.string.isRequired,
  navigation: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  language: state.session.language,
  translate: key => getTranslate(state.locale)(key, getTranslateParameters(state)),
});

export default Object.assign(
  connect(mapStateToProps)(RecordingInstructions),
  {
    hasVideo: true,
    localize,
    nextScreen,
  },
);
