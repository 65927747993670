import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

import Footer from '~components/Footer';
import Navigator from '~navigators';
import { navigationService } from '~services';

import navigationWrapper from './navigationWrapper';

const styles = EStyleSheet.create({
  container: {
    backgroundColor: '$ink',
    flex: 1,
  },
});

const WrappedNavigator = navigationWrapper(Navigator);

const AppContainer = ({ activeLanguage }) => (
  <View
    style={styles.container}
  >
    {
      activeLanguage && (
        <>
          <WrappedNavigator
            ref={navigatorRef => {
              navigationService.setTopLevelNavigator(navigatorRef);
            }}
          />
          <Footer />
        </>
      )
    }
  </View>
);

AppContainer.propTypes = {
  activeLanguage: PropTypes.object,
};

AppContainer.defaultProps = {
  activeLanguage: null,
};

const mapStateToProps = state => ({
  activeLanguage: getActiveLanguage(state.locale),
});

export default connect(mapStateToProps)(AppContainer);
