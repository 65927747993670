export default {
  title: `
    ¡Buen trabajo!
  `,
  body: `
    Es posible que estas preguntas le hayan ayudado a considerar las razones
    para vacunarse contra el coronavirus.
  `,
  body2: `
    Tómese un momento para considerar varias razones. En la siguiente pantalla,
    expondrá sus razones.
  `,
};
