import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { connect } from 'react-redux';

import { getDimensions } from '~selectors';

const ResponsiveStacker = ({ children, isLandscape, isTablet }) => (
  <View
    style={{
      flex: '1 1 auto',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxWidth: '100%',
    }}
  >
    {children.map((child, i) => (
      <View
        style={[
          {
            flex: 1,
            flexBasis: isTablet && isLandscape ? 'undefined' : 'auto',
            justifyContent: 'center',
            paddingHorizontal: isTablet && isLandscape ? 20 : 0,
            // backgroundColor: i ? 'blue' : 'red',
          },
          child.props.style,
        ]}
        key={i}
      >
        {child}
      </View>
    ))}
  </View>
);

ResponsiveStacker.propTypes = {
  children: PropTypes.node,
  isLandscape: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
};

ResponsiveStacker.defaultProps = {
  children: null,
};

const mapStateToProps = state => getDimensions(state);

export default connect(mapStateToProps)(ResponsiveStacker);
