import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  text: {
    fontSize: '$bodyFontSize',
    backgroundColor: 'transparent',
    paddingBottom: '2rem',
  },
});

const QuestionText = ({ children }) => (
  <Text style={styles.text}>
    {children}
  </Text>
);

QuestionText.propTypes = {
  children: PropTypes.node,
};

QuestionText.defaultProps = {
  children: null,
};

export default QuestionText;
