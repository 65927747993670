import * as en from './en';
import * as es from './es';

const narration = { en, es };

export default ({ property, session: { isAppointmentScheduled, language } }) => {
  if (isAppointmentScheduled === 'Y') {
    return narration[language]?._1;
  }

  if (property.hasPhoneReferral) {
    return narration[language]?._2;
  }

  if (property.hasUrlReferral) {
    return narration[language]?._3;
  }

  return narration[language]?._4;
};
