export default {
  yesBody: `
    ¡Excelente! A continuación, obtendrá más información sobre cómo vacunarse
    contra el coronavirus.
  `,
  unsureBody: `
    Como no está seguro, sería conveniente que consultara con su proveedor
    de servicios de salud sobre la vacuna. En la siguiente pantalla, tendrá
    la oportunidad de seleccionar sus inquietudes.
  `,
  noBody: `
    Parece que todavía no está preparado para recibir la vacuna contra el
    coronavirus. Esperamos que My Move le haya servido de ayuda a la hora de
    pensar en la vacuna. Esperamos que consulte más sobre este tema con su
    proveedor de servicios de salud. En la siguiente pantalla, tendrá la
    oportunidad de seleccionar sus inquietudes.
  `,
};
