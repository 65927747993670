import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { View } from 'react-native';
import ActionButton from 'react-native-action-button';
import Hamburger from 'react-native-hamburger';

import { mediaActionCreators, sessionActionCreators } from '~actions';
import { BackButton, Button, Curve, NextButton, HomeButton, Text } from '~components';
import { getDimensions } from '~selectors';
import { colors } from '~styles';

import * as localize from './localize';

export const FOOTER_HEIGHT = 90;
const FOOTER_COLOR = 'ink';
const FOOTER_COLOR_TRANSLUCENT = 'ink_translucent';

const styles = {
  curveContainer: {
    position: 'absolute',
    zIndex: 0,
    bottom: 162,
    width: '100%',
  },
  footer: {
    height: FOOTER_HEIGHT,
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    position: 'absolute',
    borderWidth: 0,
    bottom: 0,
    width: '100%',
  },
  footerTablet: {
    position: 'relative',
  },
  actionButton: {
    shadowColor: 'black',
    shadowOffset: {
      height: 4,
      width: -4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 0,
    borderRadius: 28,
  },
  actionButtonItem: {
    backgroundColor: colors.rust,
    padding: 10,
    shadowColor: 'black',
    shadowOffset: {
      height: 4,
      width: -4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 0,
    width: 'auto',
  },
  buttonGroup: {
    flexDirection: 'row',
  },
};

const actionButton = React.createRef();

const toggleAudioAction = ({ isAudioEnabled, mediaActions }) => {
  if (isAudioEnabled) {
    mediaActions.stopSound();
    mediaActions.stopAudio();
  } else {
    mediaActions.playAudio();
    mediaActions.releaseSound();
    mediaActions.loadSound();
    mediaActions.playSound();
  }
};

export const toggleLanguageAction = ({ currentLanguage, sessionActions }) => sessionActions.setLanguage(currentLanguage === 'en' ? 'es' : 'en');

const Footer = ({
  currentLanguage,
  mediaActions,
  isAudioEnabled,
  isFooterHomeEnabled,
  isFooterNavEnabled,
  isTablet,
  sessionActions,
  translate,
}) => (
  <>
    <View style={styles.curveContainer}>
      <Curve
        fillColor={isTablet ? FOOTER_COLOR : FOOTER_COLOR_TRANSLUCENT}
        heightOffset={44}
      />
    </View>
    <View
      style={[styles.footer, isTablet && styles.footerTablet]}
    >
      {
        isFooterNavEnabled && <BackButton />
      }
      {
        isTablet ? (
          <View style={styles.buttonGroup}>
            <Button
              onPress={() => toggleAudioAction({ isAudioEnabled, mediaActions })}
              title={translate(`Footer.${isAudioEnabled ? 'stopAudio' : 'playAudio'}`)}
              variant={['footer', 'footerLeft']}
            />
            <Button
              onPress={() => toggleLanguageAction({ sessionActions, currentLanguage })}
              title={translate('Footer.switchActiveLanguage')}
              variant="footer"
            />
            <HomeButton variant={['footer', 'footerRight']} />
          </View>
        ) : (
          <ActionButton
            buttonColor={colors.rust}
            degrees={0}
            offsetY={15}
            position="center"
            ref={actionButton}
            renderIcon={active => (
              <Hamburger
                active={active}
                color="white"
                onPress={() => {
                  if (actionButton.current) {
                    actionButton.current.animateButton();
                  }
                }}
              />
            )}
            shadowStyle={styles.actionButton}
          >
            <ActionButton.Item
              onPress={() => toggleLanguageAction({ sessionActions, currentLanguage })}
              shadowStyle={styles.actionButtonItem}
            >
              <Text style={{ paddingBottom: 0 }}>
                {translate('Footer.switchActiveLanguage')}
              </Text>
            </ActionButton.Item>
            <ActionButton.Item
              onPress={() => toggleAudioAction({ isAudioEnabled, mediaActions })}
              shadowStyle={styles.actionButtonItem}
            >
              <Text style={{ paddingBottom: 0 }}>
                {translate(`Footer.${isAudioEnabled ? 'stopAudio' : 'playAudio'}`)}
              </Text>
            </ActionButton.Item>
            {
              isFooterHomeEnabled && (
                <ActionButton.Item
                  onPress={() => {
                    if (actionButton.current) {
                      actionButton.current.animateButton();
                    }
                    HomeButton.action({ isFooterHomeEnabled, mediaActions, sessionActions });
                  }}
                  shadowStyle={styles.actionButtonItem}
                >
                  <Text style={{ paddingBottom: 0 }}>
                    {translate('HomeButton.home')}
                  </Text>
                </ActionButton.Item>
              )
            }
          </ActionButton>
        )
      }
      {
        isFooterNavEnabled && <NextButton />
      }
    </View>
  </>
);

Footer.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  mediaActions: PropTypes.object.isRequired,
  isAudioEnabled: PropTypes.bool.isRequired,
  isFooterHomeEnabled: PropTypes.bool.isRequired,
  isFooterNavEnabled: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  sessionActions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentLanguage: getActiveLanguage(state.locale).code,
  isAudioEnabled: state.media.isAudioEnabled,
  isFooterHomeEnabled: state.session.isFooterHomeEnabled,
  isFooterNavEnabled: state.session.isFooterNavEnabled,
  isTablet: getDimensions(state).isTablet,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  sessionActions: bindActionCreators(sessionActionCreators, dispatch),
});

export default Object.assign(
  connect(mapStateToProps, mapDispatchToProps)(Footer),
  {
    localize,
  },
);
