export default {
  question: `
    Ahora que ha pensado más en ello, seleccione la afirmación que mejor
    describa qué piensa actualmente sobre la vacuna contra el coronavirus.
  `,
  answer1: `
    Estoy indeciso sobre la posibilidad de vacunarme contra el coronavirus.
  `,
  answer2: `
    NO quiero vacunarme contra el coronavirus.
  `,
  answer3: `
    Yo SÍ quiero vacunarme contra el coronavirus.
  `,
  instruction: `
    Pulse el botón de la oración que mejor describe su opinión.
  `,
};
