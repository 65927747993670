import { createLogic } from 'redux-logic';

import {
  START_RECORDING,
  START_RECORDING_SUCCESS,
  START_RECORDING_ERROR,
  STOP_RECORDING,
  STOP_RECORDING_SUCCESS,
  STOP_RECORDING_ERROR,
  START_RECORDING_PLAYBACK,
  START_RECORDING_PLAYBACK_SUCCESS,
  START_RECORDING_PLAYBACK_ERROR,
  STOP_RECORDING_PLAYBACK,
  STOP_RECORDING_PLAYBACK_SUCCESS,
  STOP_RECORDING_PLAYBACK_ERROR,
  PLAY_SOUND,
} from '~actions/types';
import { recorderApi } from '~api';
import { recorderService } from '~services';

const startRecordingLogic = createLogic({
  type: START_RECORDING,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: START_RECORDING_SUCCESS,
    failType: START_RECORDING_ERROR,
  },
  async process({ action: { screenName }, getState }) {
    const { session: { uuid: sessionUuid } } = getState();
    const filename = recorderApi.generateFilename(screenName, sessionUuid);

    try {
      await recorderService.startRecording(filename);

      return {
        screenName,
        filename,
      };
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      return e;
    }
  },
});

const stopRecordingLogic = createLogic({
  type: STOP_RECORDING,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: STOP_RECORDING_SUCCESS,
    failType: STOP_RECORDING_ERROR,
  },
  async process({ action: { screenName }, getState }) {
    const { recorder: { isRecording } } = getState();
    if (isRecording) {
      const filename = await recorderService.stopRecording();
      const duration = await recorderService.getDurationOfFile(filename);
      return {
        screenName,
        duration,
      };
    }
    return null;
  },
});

const startRecordingPlaybackLogic = createLogic({
  type: START_RECORDING_PLAYBACK,
  cancelType: STOP_RECORDING_PLAYBACK,
  latest: true,
  async process({ action: { screenName }, getState }, dispatch, done) {
    try {
      const { session: { recordings } } = getState();
      const filename = recordings[screenName];
      const sound = await recorderApi.startRecordingPlayback(
        filename,
        () => {
          dispatch({ type: STOP_RECORDING_PLAYBACK });
          done();
        },
      );
      dispatch({ type: START_RECORDING_PLAYBACK_SUCCESS, payload: { sound } });
    } catch (error) {
      dispatch({
        type: START_RECORDING_PLAYBACK_ERROR,
        error,
      });
      done();
    }
  },
});

const stopRecordingPlaybackLogic = createLogic({
  type: [STOP_RECORDING_PLAYBACK, PLAY_SOUND, STOP_RECORDING],
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: STOP_RECORDING_PLAYBACK_SUCCESS,
    failType: STOP_RECORDING_PLAYBACK_ERROR,
  },
  process({ getState }) {
    const { recorder: { sound } } = getState();
    return recorderApi.stopRecordingPlayback(sound);
  },
});

export default [
  startRecordingLogic,
  stopRecordingLogic,
  startRecordingPlaybackLogic,
  stopRecordingPlaybackLogic,
];
