import {
  CREATE_SESSION,
  RETRIEVE_SESSION,
  STORE_SESSION,
  SET_LANGUAGE,
  SET_PARAM,
  END_SESSION,
  DELETE_SESSION,
  TOGGLE_SELECTION,
  SET_SIDE_PANEL_IMAGE,
  CHECK_FOR_SOLO_SESSION,
} from './types';

export const storeSession = () => ({
  type: STORE_SESSION,
});

export const setLanguage = value => ({
  type: SET_LANGUAGE,
  value,
});

export const setName = value => ({
  type: SET_PARAM,
  param: 'name',
  value,
});

export const setAge = value => ({
  type: SET_PARAM,
  param: 'age',
  value,
});

export const setGender = value => ({
  type: SET_PARAM,
  param: 'gender',
  value,
});

export const setReadinessQuestion1 = value => ({
  type: SET_PARAM,
  param: 'readinessQuestion1',
  value,
});

export const setReadinessQuestion2 = value => ({
  type: SET_PARAM,
  param: 'readinessQuestion2',
  value,
});

export const setReadinessQuestion3 = value => ({
  type: SET_PARAM,
  param: 'readinessQuestion3',
  value,
});

export const setIsAppointmentScheduled = value => ({
  type: SET_PARAM,
  param: 'isAppointmentScheduled',
  value,
});

export const toggleSelectedQuestion = value => ({
  type: TOGGLE_SELECTION,
  param: 'selectedQuestions',
  value,
});

export const toggleSelectedConcern = value => ({
  type: TOGGLE_SELECTION,
  param: 'selectedConcerns',
  value,
});

export const createSession = () => ({
  type: CREATE_SESSION,
});

export const checkForSoloSession = () => ({
  type: CHECK_FOR_SOLO_SESSION,
});

export const retrieveSession = passcode => ({
  type: RETRIEVE_SESSION,
  passcode,
});

export const endSession = () => ({
  type: END_SESSION,
});

export const deleteSession = () => ({
  type: DELETE_SESSION,
});

export const setNextScreen = value => ({
  type: SET_PARAM,
  param: 'nextScreen',
  value,
});

export const setIsFooterNavEnabled = value => ({
  type: SET_PARAM,
  param: 'isFooterNavEnabled',
  value,
});

export const setIsFooterBackEnabled = value => ({
  type: SET_PARAM,
  param: 'isFooterBackEnabled',
  value,
});

export const setIsNew = value => ({
  type: SET_PARAM,
  param: 'isNew',
  value,
});

export const storeRoutes = value => ({
  type: SET_PARAM,
  param: 'routes',
  value,
});

export const setSidePanelImage = image => ({
  type: SET_SIDE_PANEL_IMAGE,
  image,
});

export const setActiveRecordingScreenName = value => ({
  type: SET_PARAM,
  param: 'activeRecordingScreenName',
  value,
});

export const setIsComplete = value => ({
  type: SET_PARAM,
  param: 'isComplete',
  value,
});

export const setIsFooterHomeEnabled = value => ({
  type: SET_PARAM,
  param: 'isFooterHomeEnabled',
  value,
});
