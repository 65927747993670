import { localeReducer as locale } from 'react-localize-redux';

import auth from './authReducer';
import device from './deviceReducer';
import media from './mediaReducer';
import property from './propertyReducer';
import session from './sessionReducer';
import recorder from './recorderReducer';

export default {
  auth,
  device,
  locale,
  media,
  property,
  session,
  recorder,
};
