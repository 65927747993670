import React from 'react';
import PropTypes from 'prop-types';
import { TextInput as RNTextInput } from 'react-native';
import debounce from 'lodash.debounce';

import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  textInput: {
    fontFamily: '$fontFamily',
    backgroundColor: 'white',
    padding: '1rem 0.5rem',
    borderRadius: '$borderRadius',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '$rust',
    marginBottom: '2rem',
  },
});

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.changed = debounce(props.onChangeText, props.wait);
  }

  handleChange = value => this.setState({ value }, () => this.changed(value))

  render() {
    const { style, onChangeText, value: _value, ...props } = this.props;
    const { value } = this.state;
    return (
      <RNTextInput
        {...props}
        style={[
          styles.textInput,
          style,
        ]}
        onChangeText={this.handleChange}
        value={value}
      />
    );
  }
}

TextInput.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
  wait: PropTypes.number,
};

TextInput.defaultProps = {
  style: null,
  value: '',
  wait: 1000,
};

export default TextInput;
