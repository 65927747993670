import DoorWithHangerSign from './door-with-hanger-sign.png';
import BoyJumpingIntoPool from './boy-jumping-into-pool.png';
import DiagonalOvalTrees from './diagonal-oval-trees.png';
import DiplomaGirl from './diploma-girl.png';
import DoorHangerUpClose from './door-hanger-up-close.png';
import FlattopDudeArmsCrossedConcerned from './flattop-dude-arms-crossed-concerned.png';
import FlattopDudeHappy from './flattop-dude-happy.png';
import FlattopDudeSurprised from './flattop-dude-surprised.png';
import GirlOnBike from './girl_on_bike.png';
import GirlDriving from './girl-driving.png';
import GirlWithHeadphonesConcerned from './girl-with-headphones-concerned.png';
import GirlWithHeadphonesPointing from './girl-with-headphones-pointing.png';
import Globe from './globe.png';
import HoldingHands from './holding-hands.png';
import House from './house.png';
import SingleRoundTree from './single-round-tree.png';
import SkateboardBoy from './skateboard-boy.png';
import SkaterGirlAirCurrent from './skater-girl-air-current.png';
import SkaterGirlLeftLegForward from './skater-girl-left-leg-forward.png';
import SoccerBall from './soccer-ball.png';
import Sunshine from './sunshine.png';
import Trees1 from './trees1.png';
import Trees2 from './trees2.png';

export default {
  DoorWithHangerSign: {
    image: DoorWithHangerSign,
  },
  BoyJumpingIntoPool: {
    image: BoyJumpingIntoPool,
    style: {
      height: 450,
      bottom: 200,
    },
  },
  DiagonalOvalTrees: {
    image: DiagonalOvalTrees,
    style: {
      bottom: 110,
    },
  },
  DiplomaGirl: {
    image: DiplomaGirl,
    style: {
      width: 300,
      left: -50,
    },
  },
  DoorHangerUpClose: {
    image: DoorHangerUpClose,
  },
  FlattopDudeArmsCrossedConcerned: {
    image: FlattopDudeArmsCrossedConcerned,
    style: {
      height: '70vh',
    },
  },
  FlattopDudeHappy: {
    image: FlattopDudeHappy,
    style: {
      height: '70vh',
    },
  },
  FlattopDudeSurprised: {
    image: FlattopDudeSurprised,
    style: {
      height: '70vh',
    },
  },
  GirlOnBike: {
    image: GirlOnBike,
    style: {
      bottom: 80,
    },
  },
  GirlDriving: {
    image: GirlDriving,
  },
  GirlWithHeadphonesConcerned: {
    image: GirlWithHeadphonesConcerned,
    style: {
      height: '70vh',
    },
  },
  GirlWithHeadphonesPointing: {
    image: GirlWithHeadphonesPointing,
  },
  Globe: {
    image: Globe,
    style: {
      bottom: 90,
    },
  },
  HoldingHands: {
    image: HoldingHands,
  },
  House: {
    image: House,
  },
  SingleRoundTree: {
    image: SingleRoundTree,
  },
  SkateboardBoy: {
    image: SkateboardBoy,
  },
  SkaterGirlAirCurrent: {
    image: SkaterGirlAirCurrent,
  },
  SkaterGirlLeftLegForward: {
    image: SkaterGirlLeftLegForward,
  },
  SoccerBall: {
    image: SoccerBall,
  },
  Sunshine: {
    image: Sunshine,
  },
  Trees1: {
    image: Trees1,
  },
  Trees2: {
    image: Trees2,
  },
};
