export default {
  yesBody: `
    ¡Excelente! A continuación, obtendrá más información sobre cómo vacunarse
    contra el coronavirus.
  `,
  unsureBody: `
    Está bien. Hemos podido comprobar que la siguiente actividad ha ayudado a
    las personas que aún están considerando la posibilidad de vacunarse contra
    el coronavirus.
  `,
  noBody: `
    Parece que todavía no está preparado para vacunarse. Esperamos que My Move
    le haya ayudado a recapacitar sobre la vacuna contra el coronavirus.
    Esperamos que consulte más sobre este tema con su proveedor de servicios
    de salud. En la siguiente pantalla, tendrá la oportunidad de seleccionar
    sus inquietudes.
  `,
  instruction: `
    Pulse el botón <b>Continuar</b>.
  `,
};
