import * as en from './en';
import * as es from './es';
//
const narration = { en, es };

export default ({ session: { isComplete, language, routes } }) => {
  if (isComplete) {
    return narration[language]?._3;
  }
  if (routes.length === 0) {
    return narration[language]?._1;
  }
  return narration[language]?._2;
};
