export default {
  body: `
    Seleccione tres temas de la vacuna contra el coronavirus de esta lista.
    Seleccione los temas que más le interesen.
  `,
  topics: {
    A: `
      Protege su salud
    `,
    B: `
      Previene las infecciones, previene las enfermedades graves
    `,
    C: `
      Seguridad de las vacunas
    `,
    D: `
      Arrepentimientos futuros
    `,
    E: `
      Protege la salud de los demás
    `,
    F: `
      Protege la salud de su comunidad
    `,
    G: `
      Colabora para que las personas puedan regresar al trabajo y a la escuela
    `,
    H: `
      Siéntete más seguro
    `,
  },
  instruction: `
    Una vez que haya seleccionado tres, pulse el botón <b>Continuar</b>.
  `,
};
